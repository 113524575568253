<template>
  <div class="all-height">
    <div class="">
      <v-card loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text class="">
          <div class="d-flex mb-4">
            <v-btn-toggle v-model="toggleview" :style="`visibility: ${selectedreportingunit === 'all'?'visible':'hidden'}`">
              <v-btn class="shadow-off" small>
                <v-icon>mdi-sitemap</v-icon>
              </v-btn>
              <v-btn class="shadow-off" small>
                <v-icon>mdi-format-list-bulleted</v-icon>
              </v-btn>
            </v-btn-toggle>
            <v-spacer></v-spacer>
            <cfilter ref="childfilter" @newdata="listenfilter"></cfilter>
            <v-spacer></v-spacer>
            <v-tooltip bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="shadow-off" @click="loadContent();"><v-icon>mdi-refresh</v-icon></v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </div>
          <div class="pa-5 title grey--text text-center" v-if="role.length === 0">
            <div>Please wait as we load your data.</div>
            <div>If you are unable to see your data on this screen then kindly contact admin to provide you with access</div>
          </div>
          <div v-else>
            <div v-if="selectedyear==='all'">
              <div class="title grey--text">Select a Year</div>
              <div class="">
                <template v-for="(v,k) in year">
                  <div :key="k" @click="selectedyear=v._id" v-if="v._id !== 'all'" class="d-inline-block px-4 py-2 hoverable border-left-grey border-on cursor-pointer mr-4 mt-2">
                    {{v._id}}
                  </div>
                </template>
              </div>
            </div>
            <div v-else-if="selectedperiod==='all'">
              <div v-if="perioddata.length > 0">
                <div class="title grey--text text-center">Select a Period</div>
                <div class="text-center" v-if="toggleview===0">
                  <div class="d-flex flex-wrap justify-center">
                    <div class="" v-for="(v, k) in perioddata" :key="k">
                      <v-card class="ma-3 text-left" v-ripple @click="setFilter('selectedperiod', v._id)">
                        <v-card-text class="border-on hoverable cursor-pointer">
                          <div class="d-flex align-center">
                            <div>
                              <div class="subtitle-1 font-weight-bold">{{v.name}}</div>
                              <div class="font-weight-bold d-flex align-center">
                                <span class="mr-1 display-1 font-weight-bold grey--text text--darken-1">{{(v.summary || {}).rucount}}</span>
                                <span style="line-height:1.3" class="caption">Reporting<br/>Units</span>
                              </div>                          
                            </div>
                            <v-spacer></v-spacer>
                            <div class="ml-4">
                              <v-progress-circular
                                :size="55" :width="10" color="warning"
                                :value="($nova.formatNumber(((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100))"
                              >
                                {{ ($nova.formatNumber((((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100).toFixed(0))) }}%
                              </v-progress-circular>
                            </div>
                          </div>
                          <div class="d-flex mt-2">
                            <v-spacer></v-spacer>
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="mx-3 d-flex align-center">
                                  <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                                  <span class="body-1 ml-1">{{(v.summary || {}).cycleopen}}</span>
                                </div>
                              </template>
                              <span>Open</span>
                            </v-tooltip>
                            <!-- <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="mx-3 d-flex align-center">
                                  <v-icon>mdi-circle-off-outline</v-icon>
                                  <span class="body-1 ml-1">{{(v.summary || {}).cyclefreez}}</span>
                                </div>
                              </template>
                              <span>Freeze</span>
                            </v-tooltip> -->
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="mx-3 d-flex align-center">
                                  <v-icon>mdi-checkbox-blank-circle</v-icon>
                                  <span class="body-1 ml-1">{{(v.summary || {}).cycleclose}}</span>
                                </div>
                              </template>
                              <span>Close</span>
                            </v-tooltip>
                            <v-spacer></v-spacer>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </div>
                </div>
                <div class="mt-2" v-else>
                  <v-simple-table dense>
                    <thead>
                      <tr>
                        <th scope="slno">Sl No</th>
                        <th scope="period">Period</th>
                        <th scope="pending" class="text-center">Commentary awaited</th>
                        <th scope="pendingapproval" class="text-center">Commentary awaiting approval</th>
                        <th scope="approved" class="text-center">Approved</th>
                        <th scope="rejected" class="text-center">Rejected</th>
                        <th scope="immaterial" class="text-center">Immaterial</th>
                        <th scope="submitterprogress" class="text-center">Progress of submissions</th>
                        <th scope="approverprogress" class="text-center">Progress of approvals</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr @click="setFilter('selectedperiod', v._id)" class="hoverable cursor-pointer" v-for="(v,k) in perioddata" :key="k">
                        <td>
                          <div>
                            <v-avatar size="30" color="transperant" class="border-on">{{k+1}}</v-avatar>
                          </div>
                        </td>
                        <td class="py-1">
                          <div>
                            <div class="body-1 font-weight-bold">{{v.name}}</div>
                            <div class="grey--text text--darken-1">
                              <span class="font-weight-bold mr-2">{{(v.summary || {}).rucount || 0}}</span>
                              <span>Reporting Units</span>
                            </div>
                          </div>
                        </td>
                        <td class="subtitle-1 info--text text-center">{{(v.summary || {}).pending || 0}}</td>
                        <td class="subtitle-1 warning--text text-center">{{(v.summary || {}).pendingapproval || 0}}</td>
                        <td class="subtitle-1 success--text text-center">{{(v.summary || {}).approved || 0}}</td>
                        <td class="subtitle-1 error--text text-center">{{(v.summary || {}).rejected || 0}}</td>
                        <td class="subtitle-1 text-center">{{(v.summary || {}).immaterial || 0}}</td>
                        <td class="py-1  text-center">
                          <v-progress-circular
                            :size="55" :width="10" color="info"
                            :value="($nova.formatNumber((((v.summary || {}).pendingapproval || 0) + ((v.summary || {}).approved || 0))/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0 - (v.summary || {}).approved || 0)*100))"
                          >
                          {{$nova.formatNumber(((((v.summary || {}).pendingapproval || 0) + ((v.summary || {}).approved || 0))/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0 - (v.summary || {}).approved || 0)*100).toFixed(0))}}%
                          </v-progress-circular>
                        </td>
                        <td class="py-1  text-center">
                          <v-progress-circular
                            :size="55" :width="10" color="warning"
                            :value="($nova.formatNumber(((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100))"
                          >
                            {{ ($nova.formatNumber((((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100).toFixed(0))) }}%
                          </v-progress-circular>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </div>
              <div v-else class="pa-6 text-center title grey--text">No Data</div>
            </div>
            <div v-else-if="selectednetwork==='all'">
              <div v-if="networkdata.length > 0">
                <div class="title grey--text text-center">Select a Network</div>
                <div class="text-center" v-if="toggleview===0">
                  <div class="d-flex flex-wrap justify-center">
                    <div class="" v-for="(v, k) in networkdata" :key="k">
                      <v-card class="ma-3 text-left" v-ripple @click="setFilter('selectednetwork', v._id)">
                        <v-card-text class="border-on hoverable cursor-pointer">
                          <div class="d-flex align-center">
                            <div>
                              <div class="subtitle-1 font-weight-bold">{{v.name}}</div>
                              <div class="font-weight-bold d-flex align-center">
                                <span class="mr-1 display-1 font-weight-bold grey--text text--darken-1">{{(v.summary || {}).rucount}}</span>
                                <span style="line-height:1.3" class="caption">Reporting<br/>Units</span>
                              </div>                          
                            </div>
                            <v-spacer></v-spacer>
                            <div class="ml-4">
                              <v-progress-circular
                                :size="55" :width="10" color="warning"
                                :value="($nova.formatNumber(((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100))"
                              >
                                {{ ($nova.formatNumber((((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100).toFixed(0))) }}%
                              </v-progress-circular>
                            </div>
                          </div>
                          <div class="d-flex mt-2">
                            <v-spacer></v-spacer>
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="mx-3 d-flex align-center">
                                  <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                                  <span class="body-1 ml-1">{{(v.summary || {}).cycleopen}}</span>
                                </div>
                              </template>
                              <span>Open</span>
                            </v-tooltip>
                            <!-- <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="mx-3 d-flex align-center">
                                  <v-icon>mdi-circle-off-outline</v-icon>
                                  <span class="body-1 ml-1">{{(v.summary || {}).cyclefreez}}</span>
                                </div>
                              </template>
                              <span>Freeze</span>
                            </v-tooltip> -->
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="mx-3 d-flex align-center">
                                  <v-icon>mdi-checkbox-blank-circle</v-icon>
                                  <span class="body-1 ml-1">{{(v.summary || {}).cycleclose}}</span>
                                </div>
                              </template>
                              <span>Close</span>
                            </v-tooltip>
                            <v-spacer></v-spacer>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </div>
                </div>
                <div class="mt-2" v-else>
                  <v-simple-table dense>
                    <thead>
                      <tr>
                        <th scope="slno">Sl No</th>
                        <th scope="period">Period</th>
                        <th scope="pending" class="text-center">Commentary awaited</th>
                        <th scope="pendingapproval" class="text-center">Commentary awaiting approval</th>
                        <th scope="approved" class="text-center">Approved</th>
                        <th scope="rejected" class="text-center">Rejected</th>
                        <th scope="immaterial" class="text-center">Immaterial</th>
                        <th scope="submitterprogress" class="text-center">Progress of submissions</th>
                        <th scope="approverprogress" class="text-center">Progress of approvals</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr @click="setFilter('selectednetwork', v._id)" class="hoverable cursor-pointer" v-for="(v,k) in networkdata" :key="k">
                        <td>
                          <div>
                            <v-avatar size="30" color="transperant" class="border-on">{{k+1}}</v-avatar>
                          </div>
                        </td>
                        <td class="py-1">
                          <div>
                            <div class="body-1 font-weight-bold">{{v.name}}</div>
                            <div class="grey--text text--darken-1">
                              <span class="font-weight-bold mr-2">{{(v.summary || {}).rucount || 0}}</span>
                              <span>Reporting Units</span>
                            </div>
                          </div>
                        </td>
                        <td class="subtitle-1 info--text text-center">{{(v.summary || {}).pending || 0}}</td>
                        <td class="subtitle-1 warning--text text-center">{{(v.summary || {}).pendingapproval || 0}}</td>
                        <td class="subtitle-1 success--text text-center">{{(v.summary || {}).approved || 0}}</td>
                        <td class="subtitle-1 error--text text-center">{{(v.summary || {}).rejected || 0}}</td>
                        <td class="subtitle-1 text-center">{{(v.summary || {}).immaterial || 0}}</td>
                        <td class="py-1 text-center">
                          <v-progress-circular
                            :size="55" :width="10" color="info"
                            :value="($nova.formatNumber((((v.summary || {}).pendingapproval || 0) + ((v.summary || {}).approved || 0))/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0 - (v.summary || {}).approved || 0)*100))"
                          >
                          {{$nova.formatNumber(((((v.summary || {}).pendingapproval || 0) + ((v.summary || {}).approved || 0))/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0 - (v.summary || {}).approved || 0)*100).toFixed(0))}}%
                          </v-progress-circular>
                        </td>
                        <td class="py-1 text-center">
                          <v-progress-circular
                            :size="55" :width="10" color="warning"
                            :value="($nova.formatNumber(((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100))"
                          >
                            {{ ($nova.formatNumber((((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100).toFixed(0))) }}%
                          </v-progress-circular>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </div>
              <div v-else class="pa-6 text-center title grey--text">No Data</div>
            </div>
            <div v-else-if="selectedregion==='all'">
              <div v-if="regiondata.length > 0">
                <div class="title grey--text text-center">Select a Region</div>
                <div class="text-center" v-if="toggleview===0">
                  <div class="d-flex flex-wrap justify-center">
                    <div class="" v-for="(v, k) in regiondata" :key="k">
                      <v-card class="ma-3 text-left" v-ripple @click="setFilter('selectedregion', v._id)">
                        <v-card-text class="border-on hoverable cursor-pointer">
                          <div class="d-flex align-center">
                            <div>
                              <div class="subtitle-1 font-weight-bold">{{v.name}}</div>
                              <div class="font-weight-bold d-flex align-center">
                                <span class="mr-1 display-1 font-weight-bold grey--text text--darken-1">{{(v.summary || {}).rucount}}</span>
                                <span style="line-height:1.3" class="caption">Reporting<br/>Units</span>
                              </div>                          
                            </div>
                            <v-spacer></v-spacer>
                            <div class="ml-4">
                              <v-progress-circular
                                :size="55" :width="10" color="warning"
                                :value="($nova.formatNumber(((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100))"
                              >
                                {{ ($nova.formatNumber((((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100).toFixed(0))) }}%
                              </v-progress-circular>
                            </div>
                          </div>
                          <div class="d-flex mt-2">
                            <v-spacer></v-spacer>
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="mx-3 d-flex align-center">
                                  <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                                  <span class="body-1 ml-1">{{(v.summary || {}).cycleopen}}</span>
                                </div>
                              </template>
                              <span>Open</span>
                            </v-tooltip>
                            <!-- <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="mx-3 d-flex align-center">
                                  <v-icon>mdi-circle-off-outline</v-icon>
                                  <span class="body-1 ml-1">{{(v.summary || {}).cyclefreez}}</span>
                                </div>
                              </template>
                              <span>Freeze</span>
                            </v-tooltip> -->
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="mx-3 d-flex align-center">
                                  <v-icon>mdi-checkbox-blank-circle</v-icon>
                                  <span class="body-1 ml-1">{{(v.summary || {}).cycleclose}}</span>
                                </div>
                              </template>
                              <span>Close</span>
                            </v-tooltip>
                            <v-spacer></v-spacer>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </div>
                </div>
                <div class="mt-2" v-else>
                  <v-simple-table dense>
                    <thead>
                      <tr>
                        <th scope="slno">Sl No</th>
                        <th scope="period">Period</th>
                        <th scope="pending" class="text-center">Commentary awaited</th>
                        <th scope="pendingapproval" class="text-center">Commentary awaiting approval</th>
                        <th scope="approved" class="text-center">Approved</th>
                        <th scope="rejected" class="text-center">Rejected</th>
                        <th scope="immaterial" class="text-center">Immaterial</th>
                        <th scope="submitterprogress" class="text-center">Progress of submissions</th>
                        <th scope="approverprogress" class="text-center">Progress of approvals</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr @click="setFilter('selectedregion', v._id)" class="hoverable cursor-pointer" v-for="(v,k) in regiondata" :key="k">
                        <td>
                          <div>
                            <v-avatar size="30" color="transperant" class="border-on">{{k+1}}</v-avatar>
                          </div>
                        </td>
                        <td class="py-1">
                          <div>
                            <div class="body-1 font-weight-bold">{{v.name}}</div>
                            <div class="grey--text text--darken-1">
                              <span class="font-weight-bold mr-2">{{(v.summary || {}).rucount || 0}}</span>
                              <span>Reporting Units</span>
                            </div>
                          </div>
                        </td>
                        <td class="subtitle-1 info--text text-center">{{(v.summary || {}).pending || 0}}</td>
                        <td class="subtitle-1 warning--text text-center">{{(v.summary || {}).pendingapproval || 0}}</td>
                        <td class="subtitle-1 success--text text-center">{{(v.summary || {}).approved || 0}}</td>
                        <td class="subtitle-1 error--text text-center">{{(v.summary || {}).rejected || 0}}</td>
                        <td class="subtitle-1 text-center">{{(v.summary || {}).immaterial || 0}}</td>
                        <td class="py-1 text-center">
                          <v-progress-circular
                            :size="55" :width="10" color="info"
                            :value="($nova.formatNumber((((v.summary || {}).pendingapproval || 0) + ((v.summary || {}).approved || 0))/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0 - (v.summary || {}).approved || 0)*100))"
                          >
                          {{$nova.formatNumber(((((v.summary || {}).pendingapproval || 0) + ((v.summary || {}).approved || 0))/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0 - (v.summary || {}).approved || 0)*100).toFixed(0))}}%
                          </v-progress-circular>
                        </td>
                        <td class="py-1 text-center">
                          <v-progress-circular
                            :size="55" :width="10" color="warning"
                            :value="($nova.formatNumber(((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100))"
                          >
                            {{ ($nova.formatNumber((((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100).toFixed(0))) }}%
                          </v-progress-circular>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </div>
              <div v-else class="pa-6 text-center title grey--text">No Data</div>
            </div>
            <div v-else-if="selectedcountry==='all'">
              <div v-if="countrydata.length > 0">
                <div class="title grey--text text-center">Select a Country</div>
                <div class="text-center" v-if="toggleview===0">
                  <div class="d-flex flex-wrap justify-center">
                    <div class="" v-for="(v, k) in countrydata" :key="k">
                      <v-card class="ma-3 text-left" v-ripple @click="setFilter('selectedcountry', v._id)">
                        <v-card-text class="border-on hoverable cursor-pointer">
                          <div class="d-flex align-center">
                            <div>
                              <div class="subtitle-1 font-weight-bold">{{v.name}}</div>
                              <div class="font-weight-bold d-flex align-center">
                                <span class="mr-1 display-1 font-weight-bold grey--text text--darken-1">{{(v.summary || {}).rucount}}</span>
                                <span style="line-height:1.3" class="caption">Reporting<br/>Units</span>
                              </div>                          
                            </div>
                            <v-spacer></v-spacer>
                            <div class="ml-4">
                              <v-progress-circular
                                :size="55" :width="10" color="warning"
                                :value="($nova.formatNumber(((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100))"
                              >
                                {{ ($nova.formatNumber((((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100).toFixed(0))) }}%
                              </v-progress-circular>
                            </div>
                          </div>
                          <div class="d-flex mt-2">
                            <v-spacer></v-spacer>
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="mx-3 d-flex align-center">
                                  <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                                  <span class="body-1 ml-1">{{(v.summary || {}).cycleopen}}</span>
                                </div>
                              </template>
                              <span>Open</span>
                            </v-tooltip>
                            <!-- <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="mx-3 d-flex align-center">
                                  <v-icon>mdi-circle-off-outline</v-icon>
                                  <span class="body-1 ml-1">{{(v.summary || {}).cyclefreez}}</span>
                                </div>
                              </template>
                              <span>Freeze</span>
                            </v-tooltip> -->
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="mx-3 d-flex align-center">
                                  <v-icon>mdi-checkbox-blank-circle</v-icon>
                                  <span class="body-1 ml-1">{{(v.summary || {}).cycleclose}}</span>
                                </div>
                              </template>
                              <span>Close</span>
                            </v-tooltip>
                            <v-spacer></v-spacer>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </div>
                </div>
                <div class="mt-2" v-else>
                  <v-simple-table dense>
                    <thead>
                      <tr>
                        <th scope="slno">Sl No</th>
                        <th scope="period">Period</th>
                        <th scope="pending" class="text-center">Commentary awaited</th>
                        <th scope="pendingapproval" class="text-center">Commentary awaiting approval</th>
                        <th scope="approved" class="text-center">Approved</th>
                        <th scope="rejected" class="text-center">Rejected</th>
                        <th scope="immaterial" class="text-center">Immaterial</th>
                        <th scope="submitterprogress" class="text-center">Progress of submissions</th>
                        <th scope="approverprogress" class="text-center">Progress of approvals</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr @click="setFilter('selectedcountry', v._id)" class="hoverable cursor-pointer" v-for="(v,k) in countrydata" :key="k">
                        <td>
                          <div>
                            <v-avatar size="30" color="transperant" class="border-on">{{k+1}}</v-avatar>
                          </div>
                        </td>
                        <td class="py-1">
                          <div>
                            <div class="body-1 font-weight-bold">{{v.name}}</div>
                            <div class="grey--text text--darken-1">
                              <span class="font-weight-bold mr-2">{{(v.summary || {}).rucount || 0}}</span>
                              <span>Reporting Units</span>
                            </div>
                          </div>
                        </td>
                        <td class="subtitle-1 info--text text-center">{{(v.summary || {}).pending || 0}}</td>
                        <td class="subtitle-1 warning--text text-center">{{(v.summary || {}).pendingapproval || 0}}</td>
                        <td class="subtitle-1 success--text text-center">{{(v.summary || {}).approved || 0}}</td>
                        <td class="subtitle-1 error--text text-center">{{(v.summary || {}).rejected || 0}}</td>
                        <td class="subtitle-1 text-center">{{(v.summary || {}).immaterial || 0}}</td>
                        <td class="py-1 text-center">
                          <v-progress-circular
                            :size="55" :width="10" color="info"
                            :value="($nova.formatNumber((((v.summary || {}).pendingapproval || 0) + ((v.summary || {}).approved || 0))/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100))"
                          >
                          {{$nova.formatNumber(((((v.summary || {}).pendingapproval || 0) + ((v.summary || {}).approved || 0))/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0 - (v.summary || {}).approved || 0)*100).toFixed(0))}}%
                          </v-progress-circular>
                        </td>
                        <td class="py-1 text-center">
                          <v-progress-circular
                            :size="55" :width="10" color="warning"
                            :value="($nova.formatNumber(((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100))"
                          >
                            {{ ($nova.formatNumber((((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100).toFixed(0))) }}%
                          </v-progress-circular>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </div>
              <div v-else class="pa-6 text-center title grey--text">No Data</div>
            </div>
            <div v-else-if="selectedperiod!=='all' && selectedperiod && (selectedreportingunit==='all' || !selectedreportingunit)">
              <div v-if="reportingunitdata.length > 0">
                <div class="title grey--text text-center">Select a Reporting Unit</div>
                <div class="d-flex align-center ml-2">
                  <v-spacer></v-spacer>
                  <div v-if="multiselectdata.length > 0">
                    <v-tooltip left content-class="tooltip-left" v-if="selectedrole==='approver' || selectedrole==='manager' || selectedrole==='all'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon small @click="bulksubmit=true;commantorydialog=true;approvetype='approve';selectedcommentary={};viewdialog=false" color="success"><v-icon>mdi-check-circle</v-icon></v-btn>
                      </template>
                      <span>Approve all Selected</span>
                    </v-tooltip>
                    <v-tooltip left content-class="tooltip-left"  v-if="selectedrole==='approver' || selectedrole==='manager' ||selectedrole==='all'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon small  @click="bulksubmit=true;commantorydialog=true;approvetype='reject';selectedcommentary={};viewdialog=false" color="error"><v-icon>mdi-close-circle</v-icon></v-btn>
                      </template>
                      <span>Reject all Selected</span>
                    </v-tooltip>
                  </div>
                </div>
                <div class="text-center" v-if="toggleview===0">
                  <div class="d-flex flex-wrap justify-center">
                    <div class="" v-for="(v, k) in reportingunitdata" :key="k">
                      <v-card class="ma-3 text-left shadow-off" v-ripple @click="setFilter('selectedreportingunit', v._id)">
                        <v-card-text class="border-on hoverable cursor-pointer">
                          <div class="d-flex align-center">
                            <div>
                              <div class="subtitle-1 font-weight-bold" style="min-width:200px">{{v.name}}</div>
                              <div class="font-weight-bold d-flex align-center">
                                <v-chip label small v-if="((v.summary || {}).cycle_detail || {}).stage === 0" color="info">Open</v-chip>
                                <!-- <v-chip label small v-else-if="((v.summary || {}).cycle_detail || {}).stage === -1" color="error">Freeze</v-chip> -->
                                <v-chip label small v-else-if="((v.summary || {}).cycle_detail || {}).stage === 1" color="success">Close</v-chip>
                              </div>                          
                            </div>
                            <v-spacer></v-spacer>
                            <div class="ml-4">
                              <v-progress-circular
                                :size="55" :width="10" color="warning"
                                :value="($nova.formatNumber(((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100))"
                              >
                                {{ ($nova.formatNumber((((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100).toFixed(0))) }}%
                              </v-progress-circular>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </div>
                </div>
                <div class="mt-2" v-else>
                  <v-simple-table dense>
                    <thead>
                      <tr>
                        <th scope="multiselect" v-if="['approver','all'].indexOf(selectedrole) > -1"></th>
                        <th scope="slno">Sl No</th>
                        <th scope="period">Reporting Unit</th>
                        <th scope="total" class="text-center" v-if="selectedrole==='all'">Role Type</th>
                        <th scope="pending" class="text-center">Commentary awaited</th>
                        <th scope="pendingapproval" class="text-center">Commentary awaiting approval</th>
                        <th scope="approved" class="text-center">Approved</th>
                        <th scope="rejected" class="text-center">Rejected</th>
                        <th scope="immetarial" class="text-center">Immetarial</th>
                        <th scope="submitterprogress" class="text-center">Progress of submissions</th>
                        <th scope="approverprogress" class="text-center">Progress of approvals</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr @click="setFilter('selectedreportingunit', v._id)" class="hoverable cursor-pointer" v-for="(v,k) in reportingunitdata" :key="k">
                        <td class="pl-2 pr-0" v-if="(['approver','all'].indexOf(selectedrole) > -1)">
                          <v-checkbox v-if="(v.approver || []).indexOf(userid) > -1" multiple @click.stop="" v-model="multiselectdata" :value="v._id" hide-details dense class="pa-0 ma-0"></v-checkbox>
                        </td>
                        <td>
                          <div>
                            <v-avatar size="30" color="transperant" class="border-on">{{k+1}}</v-avatar>
                          </div>
                        </td>
                        <td class="py-1">
                          <div class="body-1 font-weight-bold">{{v.name}}</div>
                        </td>
                        <td v-if="selectedrole==='all'" class="text-center">
                          <v-chip v-if="(v.submitter || []).indexOf(userid) > -1" small label color="info">
                            Preparer
                          </v-chip>
                          <v-chip v-else-if="(v.approver || []).indexOf(userid) > -1" small label color="warning">
                            Approver
                          </v-chip>
                          <v-chip v-else-if="(v.manager || []).indexOf(userid) > -1" small label>Manager</v-chip>
                          <v-chip v-else small label color="error">
                            No Access
                          </v-chip>
                        </td>
                        <td class="subtitle-1 info--text text-center">{{(v.summary || {}).pending || 0}}</td>
                        <td class="subtitle-1 warning--text text-center">{{(v.summary || {}).pendingapproval || 0}}</td>
                        <td class="subtitle-1 success--text text-center">{{(v.summary || {}).approved || 0}}</td>
                        <td class="subtitle-1 error--text text-center">{{(v.summary || {}).rejected || 0}}</td>
                        <td class="subtitle-1 text-center">{{(v.summary || {}).immaterial || 0}}</td>
                        <td class="py-1 text-center">
                          <v-progress-circular
                            :size="55" :width="10" color="info"
                            :value="($nova.formatNumber((((v.summary || {}).pendingapproval || 0) + ((v.summary || {}).approved || 0))/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100))"
                          >
                          {{$nova.formatNumber(((((v.summary || {}).pendingapproval || 0) + ((v.summary || {}).approved || 0))/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0 - (v.summary || {}).approved || 0)*100).toFixed(0))}}%
                          </v-progress-circular>
                        </td>
                        <td class="py-1 text-center">
                          <v-progress-circular
                            :size="55" :width="10" color="warning"
                            :value="($nova.formatNumber(((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100))"
                          >
                          {{$nova.formatNumber((((v.summary || {}).approved || 0)/(((v.summary || {}).count || 0) - ((v.summary || {}).nodata || 0) - (v.summary || {}).immaterial || 0)*100).toFixed(0))}}%
                          </v-progress-circular>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </div>
              <div v-else class="pa-6 text-center title grey--text">No Data</div>
            </div>
            <div v-else-if="!ispreparer && !isapprover && !isregionalapprover && !ismanager" class="text-center title grey--text text--lighten-1 pa-5">
              No data available
            </div>
            <div v-else-if="selectedperiod!=='all' && selectedperiod && selectedreportingunit!=='all' && selectedreportingunit">
              <div class="d-flex align-center">
                <v-spacer></v-spacer>
                <div class="d-flex align-center mb-2 primary--text mx-4">
                  <span class="display-1">{{commentarysummarydata.pendingapproval + commentarysummarydata.approved + commentarysummarydata.pending + commentarysummarydata.rejected + commentarysummarydata.immaterial}}</span>
                  <span class="ml-1 line-1-2">Total<br/>GLs</span>
                </div>
                <div class="d-flex align-center mb-2 success--text mx-4">
                  <span class="display-1">{{commentarysummarydata.approved}}</span>
                  <span class="ml-1 line-1-2">Approved<br/>GLs</span>
                </div>
                <div class="d-flex align-center mb-2 info--text mx-4">
                  <span class="display-1">{{commentarysummarydata.pending}}</span>
                  <span class="ml-1 line-1-2">Awaiting Commentary<br/>GLs</span>
                </div>
                <div class="d-flex align-center mb-2 warning--text mx-4">
                  <span class="display-1">{{commentarysummarydata.pendingapproval}}</span>
                  <span class="ml-1 line-1-2">Awaiting Approval<br/>GLs</span>
                </div>
                <div class="d-flex align-center mb-2 error--text mx-4">
                  <span class="display-1">{{commentarysummarydata.rejected}}</span>
                  <span class="ml-1 line-1-2">Rejected<br/>GLs</span>
                </div>
                <div class="d-flex align-center mb-2 grey--text mx-4">
                  <span class="display-1">{{commentarysummarydata.immaterial}}</span>
                  <span class="ml-1 line-1-2">Immaterial<br/>GLs</span>
                </div>
                <v-spacer></v-spacer>
              </div>
              <div class="mb-4 d-flex align-center" v-if="commentarydata.length > 0">
                <div class="mr-2">
                  <v-chip label small color="info" v-if="(commentarysummarydata.cycle_detail || {}).stage === 0">Open</v-chip>
                  <!-- <v-chip label small color="error" v-else-if="(commentarysummarydata.cycle_detail || {}).stage === -1">Freeze</v-chip> -->
                  <v-chip label small color="success" v-else-if="(commentarysummarydata.cycle_detail || {}).stage === 1">Close</v-chip>
                </div>
                <div class="mr-2">
                  <v-chip label small color="info" v-if="ispreparer">Preparer</v-chip>
                  <v-chip label small color="warning" v-else-if="isapprover">Approver</v-chip>
                  <v-chip label small color="success" v-else-if="isregionalapprover">Regional Approver</v-chip>
                  <v-chip label small v-else-if="ismanager">Manager</v-chip>
                </div>
                <!-- <v-chip small label class="pr-0 mr-2" outlined>Currency<v-chip class="ml-2" small label>{{commentarymetadata.currency}}</v-chip></v-chip> -->
                <v-menu offset-y transition="slide-y-transition" :close-on-content-click="false" dense>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small class="shadow-off" v-bind="attrs" v-on="on">Filter<v-icon class="ml-1">mdi-filter-menu</v-icon></v-btn>
                  </template>
                  <v-list dense class="">
                    <v-list-item>
                      <v-list-item-title>
                        <v-checkbox color="" dense hide-details class="mr-4" label="Ignore Nil Values" v-model="ignore0values"></v-checkbox>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-checkbox color="info" dense hide-details class="mr-4" label="Commentary awaited" v-model="selectallpending"></v-checkbox>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-checkbox color="warning" dense hide-details class="mr-4" label="Commentary awaiting approval" v-model="selectallpendingapproval"></v-checkbox>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-checkbox color="success" dense hide-details class="mr-4" label="Approved" v-model="selectallapproved"></v-checkbox>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-checkbox color="error" dense hide-details class="mr-4" label="Rejected" v-model="selectallrejected"></v-checkbox>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-checkbox color="" dense hide-details class="mr-4" label="Immaterial" v-model="selectallimmaterial"></v-checkbox>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-switch
                v-if="customfilter.length > 0"
                  inset
                  class="ml-4 mr-0 mt-0"
                  v-model="customfilterstoggle"
                  label="Custom View"
                  hide-details
                ></v-switch>
                
                
                <v-spacer></v-spacer>
                <div class="d-flex align-center ml-2" v-if="(multiselectdata || []).length > 0">
                  <v-tooltip left content-class="tooltip-left" v-if="(isapprover) && rucycle === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon small  class="" @click="bulksubmit=true;requestcommentarydialog=true;requestcommentarycontrol=false;requestcommentarymanagement=false;selectedcommentary={}"><v-icon>mdi-comment-plus-outline</v-icon></v-btn>
                    </template>
                    <span>Request Commentary for Selected</span>
                  </v-tooltip>
                  <v-tooltip left content-class="tooltip-left" v-if="ispreparer && rucycle === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon small  class="" @click="requestcommentarycontrol = false;requestcommentarymanagement = false;bulksubmit=true;bulkcommentall=false;bulkcommentreplace=false;commantorydialog=true;selectedcommentary={}"><v-icon>mdi-plus-circle</v-icon></v-btn>
                    </template>
                    <span>Add Commentary to Selected</span>
                  </v-tooltip>
                  <v-tooltip left content-class="tooltip-left" v-if="(isapprover) && rucycle === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon small  class="" @click="bulksubmit=true;commantorydialog=true;approvetype='approve';selectedcommentary={};viewdialog=false" color=""><v-icon>mdi-check-circle</v-icon></v-btn>
                    </template>
                    <span>Approve/Reject all Selected</span>
                  </v-tooltip>
                  <!-- <v-tooltip left content-class="tooltip-left" v-if="(isapprover) && rucycle === 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon small  class="" @click="bulksubmit=true;commantorydialog=true;approvetype='reject';selectedcommentary={};viewdialog=false" color="error"><v-icon>mdi-close-circle</v-icon></v-btn>
                    </template>
                    <span>Reject all Selected</span>
                  </v-tooltip> -->
                </div>
                <v-tooltip v-if="(commentarysummarydata.cycle_detail || {}).stage === 0 && (ispreparer || ismanager)" left content-class="tooltip-left">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" small icon class="" color="warning" @click="sendApprovalReminder()"><v-icon>mdi-email</v-icon></v-btn>
                  </template>
                  <span>Send Reminder mail to approver</span>
                </v-tooltip>
                <v-tooltip v-if="(commentarysummarydata.cycle_detail || {}).stage === 0 && (isapprover || ismanager)" left content-class="tooltip-left">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" small icon class="" color="info" @click="sendSubmitterReminder()"><v-icon>mdi-email</v-icon></v-btn>
                  </template>
                  <span>Send Reminder mail to preparer</span>
                </v-tooltip>
                <!-- <v-tooltip v-if="(commentarysummarydata.cycle_detail || {}).stage === 0 && ismanager" left content-class="tooltip-left">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" small icon class="" @click="updateCycle('freez')"><v-icon>mdi-circle-off-outline</v-icon></v-btn>
                  </template>
                  <span>Freeze Commentary Cycle</span>
                </v-tooltip> -->
                <v-tooltip v-if="(commentarysummarydata.cycle_detail || {}).stage === 0 && ismanager" left content-class="tooltip-left">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" small icon class="" @click="updateCycle('close')"><v-icon>mdi-checkbox-blank-circle</v-icon></v-btn>
                  </template>
                  <span>Close Commentary Cycle</span>
                </v-tooltip>
                <v-tooltip v-if="(commentarysummarydata.cycle_detail || {}).stage === 1 && (ismanager || isapprover)" left content-class="tooltip-left">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" small icon class="" @click="updateCycle('open')"><v-icon>mdi-checkbox-blank-circle-outline</v-icon></v-btn>
                  </template>
                  <span>Open Commentary Cycle</span>
                </v-tooltip>
                <!-- <v-tooltip v-if="(commentarysummarydata.cycle_detail || {}).stage === -1 && ismanager" left content-class="tooltip-left">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" small icon class="" @click="updateCycle('open')"><v-icon>mdi-checkbox-blank-circle-outline</v-icon></v-btn>
                  </template>
                  <span>Unfreeze Commentary Cycle</span>
                </v-tooltip> -->
                <v-tooltip left content-class="tooltip-left">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" small icon class="" target="_blank" @click="downloadExcel()"><v-icon>mdi-file-excel-outline</v-icon></v-btn>
                  </template>
                  <span>Download Excel</span>
                </v-tooltip>
                <v-tooltip left content-class="tooltip-left">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" small icon class="" target="_blank" @click="$nova.downloadFile('', {}, '/v2/varianceanalysis/analysis/downloadppt/'+selectedreportingunit+'/p/'+selectedperiod+'/c/'+$store.state.client)"><v-icon>mdi-presentation</v-icon></v-btn>
                  </template>
                  <span>Download PPT</span>
                </v-tooltip>
                <lb-conversation
                  heading="Conversation"
                  width="600"
                  :recurring="false"
                  :recurringText="''"
                  :closed="commentarycycledata.stage !== 0"
                  @markread="commentarycycledata.conversation_read = true"
                  :notify="!commentarycycledata.conversation_read"
                  :getapi="'/v2/varianceanalysis/analysis/getconversation/'+commentarycycledata._id"
                  :sendapi="'/v2/varianceanalysis/analysis/addconversation/'+commentarycycledata._id"
                  :readapi="'/v2/varianceanalysis/analysis/markreadconversation/'+commentarycycledata._id"
                />
                <v-btn color="primary" class="ml-4" small v-if="ispreparer && rucycle === 0" @click="bulksubmitdialog=true">Bulk submit Commentary</v-btn>
                <div class="ml-2">
                  <div style="line-height:0.8">
                    <v-icon class="error--text">mdi-circle-medium</v-icon>
                    <span>Material variance - Controls</span>
                  </div>
                  <div style="line-height:0.8">
                    <v-icon class="blue--text">mdi-circle-medium</v-icon>
                    <span>Material variance - Management</span>
                  </div>
                </div>
              </div>
              <div class="" v-if="filteredcommentarydata.length > 0">
                <!-- {{commentarydata[0]}} -->
                <v-simple-table dense height="55vh" fixed-header id="exceltable">
                  <thead>
                    <tr class="">
                      <th scope="multiselect" v-if="(ispreparer || isapprover) && rucycle === 0"></th>
                      <th scope="type" class="text-center" rowspan="2">
                        <span>GL Code</span><br/>
                        <v-btn small class="shadow-off" icon @click="customfiltersdialog=true"><v-icon>mdi-filter-menu</v-icon></v-btn>
                      </th>
                      <th scope="description" rowspan="2">Description</th>
                      <th scope="amount1heading" rowspan="2" class="text-right" :style="`background-color: #bfffbf !important`">{{(type==='forecast')?'Forecast':commentarydata[0].amount1heading}}</th>
                      <th scope="type" class="text-center" :style="`background-color: ${tablecolors.heading.a} !important`" colspan="4">{{(type==='forecast')?'Forecast':commentarydata[0].amount1heading}} Vs {{commentarydata[0].amount2heading}}</th>
                      <th scope="type" class="text-center" :style="`background-color: ${tablecolors.heading.b} !important`" colspan="4">{{(type==='forecast')?'Forecast':commentarydata[0].amount1heading}} Vs {{(type==='forecast')?'Previous Forecast':commentarydata[0].amount3heading}}</th>
                      <th scope="variancepercent" class="text-center" rowspan="2">Action</th>
                      <th scope="status" class="text-center" rowspan="2">
                        Status<br/>
                        <v-btn icon small @click="statushelpdialog=true"><v-icon>mdi-help-circle</v-icon></v-btn>
                      </th>
                    </tr>
                    <tr>
                      <th scope="multiselect" v-if="(ispreparer || isapprover) && rucycle === 0"></th>
                      <th scope="amount2heading" class="text-right" :style="`background-color: ${tablecolors.heading.a} !important`">{{commentarydata[0].amount2heading}}</th>
                      <th scope="varianceamt" class="text-right" :style="`background-color: ${tablecolors.heading.a} !important`">Variance Amt</th>
                      <th scope="variancepercent" class="text-right" :style="`background-color: ${tablecolors.heading.a} !important`">Variance %</th>
                      <th scope="variancepercent" class="" :style="`background-color: ${tablecolors.heading.a} !important`">Commentary</th>
                      <th scope="amount2heading" class="text-right" :style="`background-color: ${tablecolors.heading.b} !important`">{{(type==='forecast')?'Previous Forecast':commentarydata[0].amount3heading}}</th>
                      <th scope="varianceamt" class="text-right" :style="`background-color: ${tablecolors.heading.b} !important`">Variance Amt</th>
                      <th scope="variancepercent" class="text-right" :style="`background-color: ${tablecolors.heading.b} !important`">Variance %</th>
                      <th scope="variancepercent" :style="`background-color: ${tablecolors.heading.b} !important`">Commentary</th>
                    </tr>
                  </thead>
                  <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                  <!-- <tbody> -->
                    <template v-for="(v,k) in filteredcommentarydata">
                      <tr :class="`${v.type==='header'?'':'hoverable'} ${v.glgroup!=='GL'?'font-weight-bold':''}`" :key="k" v-if="v.glgroup !== 'GL' || v.type==='header' || expandedsubcategory.indexOf(v.sub_category) > -1">
                        <template v-if="['Calculation'].indexOf(v.glgroup) > -1 || v.type==='header' || expandedsubcategory.indexOf(v.sub_category) > -1">
                          <td :class="`pl-2 pr-0 ${((ispreparer || isapprover) && rucycle === 0)?(v.glgroup==='Calculation'?'border-left-grey':(v.type==='header'?'border-left-caccounting':'border-left-transparent')):''}`" v-if="(ispreparer || isapprover) && rucycle === 0">
                            <v-checkbox v-if="((ispreparer && ([2].indexOf(v.stage) === -1)) || ((isapprover) && [1].indexOf(v.stage) > -1))" multiple v-model="multiselectdata" :value="v._id" hide-details dense class="pa-0 ma-0"></v-checkbox>
                          </td>
                          <td :class="`${(!((ispreparer || isapprover || ismanager) && rucycle === 0))?(v.glgroup==='Calculation'?'border-left-grey':(v.type==='header'?'border-left-caccounting':'border-left-transparent')):''}`">
                            <div>{{v.glcode}}</div>
                          </td>
                          <td>
                            <div class="d-flex align-center cursor-pointer" v-if="v.type==='header'" @click="toggleSubCategory(v.sub_category)">
                              <span>{{v.description}}</span>
                              <div>
                                <v-icon class="ml-2" v-if="expandedsubcategory.indexOf(v.sub_category) > -1">mdi-chevron-down</v-icon>
                                <v-icon class="ml-2" v-else>mdi-chevron-right</v-icon>
                              </div>
                            </div>
                            <span v-else>{{v.description}}</span>
                          </td>
                          <td class="text-right" :style="`background-color: rgba(0,250,0,0.05) !important`">{{$nova.formatCurrency(v.amount1.toFixed(1), '', false, true)}}</td>
                          <td class="text-right" :style="`background-color: ${tablecolors.body.a} !important`">{{$nova.formatCurrency(v.amount2.toFixed(1), '', false, true)}}</td>
                          <td class="" :style="`background-color: ${tablecolors.body.a} !important`">
                            <div class="d-flex align-center">
                              <div class="ml-2 d-flex flex-column">
                                <!-- {{v.var1_cross_management}},{{v.var2_cross_management}} -->
                                <v-icon style="line-height:0.6" v-if="v.var1_cross_management && !v.managementignore" class="blue--text">mdi-circle-medium</v-icon>
                                <v-icon style="line-height:0.6" v-if="v.var1_cross_control" class="error--text">mdi-circle-medium</v-icon>
                              </div>
                              <v-spacer></v-spacer>
                              <div :class="`${(v.varianceamount1>0)?'success--text font-weight-bold':((v.varianceamount1<0)?'error--text font-weight-bold':'')}`">{{$nova.formatCurrency(v.varianceamount1.toFixed(1), '', false, true)}}</div>
                            </div>
                          </td>
                          <td :style="`background-color: ${tablecolors.body.a} !important`" :class="`${(v.varianceamount1>0)?'success--text font-weight-bold':((v.varianceamount1<0)?'error--text font-weight-bold':'')} text-right`">{{$nova.formatCurrency((v.variancepercent1*100).toFixed(1), '', false, true)}}{{((v.variancepercent1 !== 0)?'%':'')}}</td>
                          <td :style="`background-color: ${tablecolors.body.a} !important; max-width: 150px`" class="ellipsis-on">
                            <span v-if="!v.ignorecommentary">{{v.commentary1}}</span>
                          </td>
                          <td class="text-right" :style="`background-color: ${tablecolors.body.b} !important`">{{$nova.formatCurrency(v.amount3.toFixed(1), '', false, true)}}</td>
                          <td :style="`background-color: ${tablecolors.body.b} !important`">
                            <div class="d-flex align-center">
                              <div class="ml-2 d-flex flex-column">
                                <!-- {{v.var1_cross_management}},{{v.var2_cross_management}} -->
                                <v-icon style="line-height:0.6" v-if="v.var2_cross_management && !v.managementignore" class="blue--text">mdi-circle-medium</v-icon>
                                <v-icon style="line-height:0.6" v-if="v.var2_cross_control" class="error--text">mdi-circle-medium</v-icon>
                              </div>
                              <v-spacer></v-spacer>
                              <div :class="`${(v.varianceamount2>0)?'success--text font-weight-bold':((v.varianceamount2<0)?'error--text font-weight-bold':'')}`">{{$nova.formatCurrency(v.varianceamount2.toFixed(1), '', false, true)}}</div>
                            </div>
                          </td>
                          <td :style="`background-color: ${tablecolors.body.b} !important`" :class="`${(v.varianceamount2>0)?'success--text font-weight-bold':((v.varianceamount2<0)?'error--text font-weight-bold':'')} text-right`">{{$nova.formatCurrency((v.variancepercent2*100).toFixed(1), '', false, true)}}{{((v.variancepercent2 !== 0)?'%':'')}}</td>
                          <td :style="`background-color: ${tablecolors.body.b} !important; max-width: 150px`" class="ellipsis-on">
                            <span v-if="!v.ignorecommentary">{{v.commentary2}}</span>
                          </td>
                          <td>
                            <div class="d-flex justify-center">
                              <v-tooltip v-if="[0,-1,1].indexOf(v.stage) === -1 && (isapprover) && rucycle === 0" bottom content-class="tooltip-bottom">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click="bulksubmit=false;requestcommentarycontrol=false;requestcommentarymanagement=false;selectedcommentary={...v};requestcommentarydialog=true" v-bind="attrs" v-on="on" icon small><v-icon>mdi-comment-plus-outline</v-icon></v-btn>
                                </template>
                                <span>Request Commentary</span>
                              </v-tooltip>
                              <v-tooltip v-if="[5].indexOf(v.stage) === -1 && ispreparer && rucycle === 0 && !v.ignorecommentary" bottom content-class="tooltip-bottom">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click="openSubmitDialog(v, 'add')" v-bind="attrs" v-on="on" icon small><v-icon>mdi-plus-circle</v-icon></v-btn>
                                </template>
                                <span>Add Commentary</span>
                              </v-tooltip>
                              <v-tooltip v-if="v.stage === 1 && (isapprover) && rucycle === 0 && !v.ignorecommentary" bottom content-class="tooltip-bottom">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click="bulksubmit=false;viewdialog=false;selectedcommentary={...v};getTrend(v.glcode);commantorydialog=true;approvetype='approve'" color="" v-bind="attrs" v-on="on" icon small><v-icon>mdi-check-circle</v-icon></v-btn>
                                </template>
                                <span>Approve/Reject</span>
                              </v-tooltip>
                              <v-tooltip v-if="v.stage === 2 && (isregionalapprover) && rucycle === 0 && !v.ignorecommentary" bottom content-class="tooltip-bottom">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click="bulksubmit=false;viewdialog=false;selectedcommentary={...v};getTrend(v.glcode);commantorydialog=true;approvetype='regionalapprove'" color="" v-bind="attrs" v-on="on" icon small><v-icon>mdi-check-circle</v-icon></v-btn>
                                </template>
                                <span>Approve/Reject</span>
                              </v-tooltip>
                              <!-- <v-tooltip v-if="v.stage === 1 && (isapprover) && rucycle === 0 && !v.ignorecommentary" bottom content-class="tooltip-bottom">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click="bulksubmit=false;viewdialog=false;selectedcommentary={...v};getTrend(v.glcode);commantorydialog=true;approvetype='reject'" color="error" v-bind="attrs" v-on="on" icon small><v-icon>mdi-close-circle</v-icon></v-btn>
                                </template>
                                <span>Reject</span>
                              </v-tooltip> -->
                              <lb-conversation
                                heading="Conversation"
                                width="600"
                                :recurring="false"
                                :recurringText="''"
                                :closed="commentarycycledata.stage !== 0"
                                @markread="v.conversation_read = true"
                                :notify="!v.conversation_read"
                                :getapi="'/v2/varianceanalysis/analysis/getconversation/'+commentarycycledata._id+'/c/'+v._id"
                                :sendapi="'/v2/varianceanalysis/analysis/addconversation/'+commentarycycledata._id+'/c/'+v._id"
                                :readapi="'/v2/varianceanalysis/analysis/markreadconversation/'+commentarycycledata._id+'/c/'+v._id"
                              />
                              <!-- <v-tooltip bottom content-class="tooltip-bottom" v-if="!v.ignorecommentary">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click="markCommentAsRead(v, 'commentary');selectedcommentary={...v};commentsdialog=true;commentslevel='commentary';" v-bind="attrs" v-on="on" icon small>
                                    <v-badge color="error" offset-x="10" offset-y="10" dot v-if="(v.comments || []).length > 0 && (((v.comments || [])[(v.comments || []).length - 1] || {}).readby || []).indexOf($store.state.user.id) === -1">
                                      <v-icon class="">mdi-comment-text-multiple</v-icon>
                                    </v-badge>
                                    <v-icon v-else class="">mdi-comment-text-multiple</v-icon>
                                  </v-btn>
                                </template>
                                <span>Conversations</span>
                              </v-tooltip> -->
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="!v.ignorecommentary">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click="readActivity(v._id)" v-bind="attrs" v-on="on" icon small><v-icon>mdi-history</v-icon></v-btn>
                                </template>
                                <span>Activity log</span>
                              </v-tooltip>
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="ismanager || v.stage === 5 || (isregionalapprover && v.stage !== 2) || (isapprover && v.stage !== 1)">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click="approvetype='';viewdialog=true;bulksubmit=false;selectedcommentary={...v};getTrend(v.glcode);commantorydialog=true;" v-bind="attrs" v-on="on" icon small><v-icon>mdi-eye</v-icon></v-btn>
                                </template>
                                <span>View Details</span>
                              </v-tooltip>
                            </div>
                          </td>
                          <td class="text-center" v-if="!v.ignorecommentary">
                            <v-tooltip left content-class="tooltip-left" v-if="v.stage === -1">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="error">mdi-close-circle</v-icon>
                              </template>
                              <span>Rejected commentary</span>
                            </v-tooltip>
                            <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 0">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
                              </template>
                              <span>Commentary awaited</span>
                            </v-tooltip>
                            <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 1">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="warning">mdi-timer-sand</v-icon>
                              </template>
                              <span>Commentary awaiting approval</span>
                            </v-tooltip>
                            <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 2">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="warning">mdi-check-circle</v-icon>
                              </template>
                              <span>Pending Regional Approval</span>
                            </v-tooltip>
                            <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 5">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                              </template>
                              <span>Approved commentary</span>
                            </v-tooltip>
                            <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 3">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="grey">mdi-bookmark-off</v-icon>
                              </template>
                              <span>Nil value accounts</span>
                            </v-tooltip>
                            <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 4">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="grey">mdi-cash-remove</v-icon>
                              </template>
                              <span>Immaterial variance</span>
                            </v-tooltip>
                          </td>
                          <td v-else></td>
                        </template>
                      </tr>
                    </template>
                  </v-slide-y-transition>
                </v-simple-table>
              </div>
              <div v-else class="title grey--text pa-5 text-center">No data found</div>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <v-dialog v-model="statushelpdialog" max-width="600">
        <v-card class="">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Status Notations</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="statushelpdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="pa-4">
            <div class="my-1">
              <v-icon color="error">mdi-close-circle</v-icon>
              <span class="ml-2">Rejected commentary</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="info">mdi-timer-sand</v-icon>
              <span class="ml-2">Commentary awaiting local submission</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="warning">mdi-timer-sand</v-icon>
              <span class="ml-2">Commentary awaiting local approval</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="warning">mdi-check-circle</v-icon>
              <span class="ml-2">Commentary awaiting regional approval</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="success">mdi-check-circle</v-icon>
              <span class="ml-2">Approved commentary</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="grey">mdi-bookmark-off</v-icon>
              <span class="ml-2">Nil value accounts</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="gray">mdi-cash-remove</v-icon>
              <span class="ml-2">Immaterial variance</span>
            </div>
            <v-divider></v-divider>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="bulksubmitdialog" max-width="1000">
        <v-card class="" loader-height="2" :loading="bulksubmitloading" :disabled="bulksubmitloading">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Bulk Upload Commentary</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="bulksubmitdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="pa-4">
            <div class="mb-2 d-flex align-center">
              <v-btn color="primary" small class="mr-2" @click="$nova.downloadFile(id, {}, '/v2/varianceanalysis/analysis/getbulksubmittemplate')">Download Template</v-btn>
              <lb-file v-model="bulksubmitfiledatafile" label=""
                :drag="false" hidedetails @change="readBulkSubmit()" :displayname="false" accept=".xlsx,.xls,.xlsm,.xlsb,.pdf"
              />
              <v-spacer></v-spacer>
              <v-btn v-if="Object.keys(bulksubmitfiledata).length > 0 && !bulksubmitfiledataerror" @click="bulkUploadCommentaryByGL()" color="success" small class="ml-2">Proceed to Upload</v-btn>
              <lb-file v-model="bulksubmitfiledatafile" label=""
                :drag="false" hidedetails @change="readBulkSubmit()" :displayname="false" accept=".xlsx,.xls,.xlsm,.xlsb,.pdf"
              />
            </div>
            <div v-if="Object.keys(bulksubmitfiledata).length > 0">
              <div v-if="bulksubmitfiledataerror" class="error--text font-weight-bold">
                We found few errors. Please rectify the same to proceed with the upload
              </div>
              <v-simple-table dense class="v-table-color" >
                <thead>
                  <tr>
                    <th scope="flag"></th>
                    <th scope="glcode">GL Code</th>
                    <th scope="glcode">GL Description</th>
                    <th scope="commentary1">Commentary 1</th>
                    <th scope="commentary2">Commentary 2</th>
                    <th scope="commentary2" v-if="bulksubmitfiledataerror">Error</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(v,k) in bulksubmitfiledata">
                    <tr class="hoverable" :key="k">
                      <td style="width:30px;">
                        <v-icon v-if="(v.error || !v.__found)" color="error">mdi-close-circle</v-icon>
                        <v-icon v-else color="success">mdi-check-circle</v-icon>
                      </td>
                      <td>{{v.data[0]}}</td>
                      <td>{{v.description}}</td>
                      <td>{{v.data[1] || ""}}</td>
                      <td>{{v.data[2] || ""}}</td>
                      <td class="error--text" v-if="bulksubmitfiledataerror">
                        <span v-if="!v.__found">GL Code not found</span>
                        <span v-else>{{v.error}}</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </v-simple-table>
            </div>
            <div v-else class="grey--text pa-6 text-center title">
              Upload template file
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="commantorydialog" max-width="800" eager>
        <v-card class="" loader-height="2" :loading="commentaryloading" :disabled="commentaryloading">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <div class="subtitle-1 white--text" v-if="bulksubmit">
              <span>Bulk Commentary</span>
              <!-- <span v-if="approvetype==='approve'">Bulk Commentary Approval</span>
              <span v-else-if="approvetype==='reject'">Bulk Commentary Rejection</span>
              <span v-else-if="approvetype==='' && ispreparer">Bulk Commentary Submission</span>
              <span v-else>View Commentary Submission</span> -->
            </div>
            <span class="subtitle-1 white--text" v-else>
              <span>Commentary</span>
              <!-- <span v-if="approvetype==='approve'">Commentary Approval</span>
              <span v-else-if="approvetype==='reject'">Commentary Rejection</span>
              <span v-else-if="approvetype==='' && ispreparer">Commentary Submission</span>
              <span v-else>View Commentary Submission</span> -->
            </span>
            <v-spacer></v-spacer>
            <v-btn icon @click="commantorydialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="py-4 px-0">
            <div class="d-flex px-4">
              <div v-if="!bulksubmit" class="mb-2">
                <v-chip :style="`background-color:${$nova.hexToRgbA($vuetify.theme.currentTheme.error, 0.1)}`" small label v-if="selectedcommentary.stage === -1">
                  <v-icon color="error" class="mr-2">mdi-close-circle</v-icon>
                  <span class="error--text font-weight-bold">Rejected</span>
                </v-chip>
                <v-chip :style="`background-color:${$nova.hexToRgbA($vuetify.theme.currentTheme.info, 0.1)}`" small label v-if="selectedcommentary.stage === 0">
                  <v-icon color="info" class="mr-2">mdi-timer-sand</v-icon>
                  <span class="info--text font-weight-bold">Awaiting commentary submission</span>
                </v-chip>
                <v-chip :style="`background-color:${$nova.hexToRgbA($vuetify.theme.currentTheme.warning, 0.1)}`" small label v-if="selectedcommentary.stage === 1">
                  <v-icon color="warning" class="mr-2">mdi-timer-sand</v-icon>
                  <span class="warning--text font-weight-bold">Local approval awaited</span>
                </v-chip>
                <v-chip :style="`background-color:${$nova.hexToRgbA($vuetify.theme.currentTheme.warning, 0.1)}`" small label v-if="selectedcommentary.stage === 2">
                  <v-icon color="warning" class="mr-2">mdi-check-circle</v-icon>
                  <span class="warning--text font-weight-bold">Regional approval awaited</span>
                </v-chip>
                <v-chip :style="`background-color:${$nova.hexToRgbA($vuetify.theme.currentTheme.success, 0.1)}`" small label v-if="selectedcommentary.stage === 5">
                  <v-icon color="success" class="mr-2">mdi-check-circle</v-icon>
                  <span class="success--text font-weight-bold">Approved</span>
                </v-chip>
              </div>
              <v-spacer></v-spacer>
              <div v-if="[-1,0,1].indexOf(selectedcommentary.stage) > -1 && ispreparer" class="mb-2">
                <span class="success--text" v-if="selectedcommentary.savedcommentary1 === selectedcommentary.commentary1 && selectedcommentary.savedcommentary2 === selectedcommentary.commentary2">Saved</span>
                <span class="error--text" v-else>Unsaved</span>
              </div>
            </div>
            <v-tabs v-model="charttab" background-color="transparent" height="30" class="" v-if="!bulksubmit">
              <v-tab class="text-transform-none" key="0">Commentary</v-tab>
              <v-tab class="text-transform-none" key="1">Trend Line</v-tab>
            </v-tabs>
            <v-divider v-if="!bulksubmit"></v-divider>
            <v-tabs-items v-model="charttab">
              <v-tab-item key="0" eager>
                <div>
                  <div class="px-4 mt-2" v-if="!bulksubmit">
                    <div class="title">{{selectedcommentary.glcode}}</div>
                    <div class="subtitle-1 mb-2">{{selectedcommentary.description}}</div>
                  </div>
                  <div v-else>
                    <div class="title px-4 my-2">
                      <span>{{multiselectdata.length}}</span>
                      Selected
                    </div>
                    <div class="px-4 pb-2" v-if="([-1,0,1].indexOf(selectedcommentary.stage) > -1 || bulksubmit) && ispreparer">
                      <v-checkbox hide-details class="pa-0 ma-0" dense v-model="bulkcommentall" label="Add commentary to non-mandatory variance?"></v-checkbox>
                      <v-checkbox hide-details class="pa-0 ma-0" dense v-model="bulkcommentreplace" label="Replace existing comments?"></v-checkbox>
                    </div>
                    <div class="px-4 mb-4" v-if="bulkcommentall">
                      <div>Voluntary commentary submission reason</div>
                      <v-checkbox hide-details class="pa-0 ma-0" dense v-model="requestcommentarycontrol" label="For the purpose of controls?"></v-checkbox>
                      <v-checkbox hide-details class="pa-0 ma-0" dense v-model="requestcommentarymanagement" label="For the purpose of management?"></v-checkbox>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="pl-4 pr-2 pb-4" style="width:50%">
                      <div v-if="!bulksubmit" class="pt-2">
                        <div class="body-1 pl-2" :style="`border-left: 4px solid ${tablecolors.heading.a} !important`">{{selectedcommentary.amount1heading}} Vs {{selectedcommentary.amount2heading}}</div>
                        <div class="d-flex">
                          <div class="mr-2 py-1">
                            <div class="font-weight-bold grey--text">{{selectedcommentary.amount1heading}}</div>
                            <div class="subtitle-2">{{$nova.formatCurrency(selectedcommentary.amount1, '', false, true)}}</div>
                          </div>
                          <div class="mx-2 py-1">
                            <div class="font-weight-bold grey--text">{{selectedcommentary.amount2heading}}</div>
                            <div class="subtitle-2">{{$nova.formatCurrency(selectedcommentary.amount2, '', false, true)}}</div>
                          </div>
                          <div class="ml-2 py-1">
                            <div class="font-weight-bold grey--text">Variance Amt / %</div>
                            <div class="subtitle-2">
                              <span :class="`${(selectedcommentary.varianceamount1>0)?'success--text font-weight-bold':((selectedcommentary.varianceamount1<0)?'error--text font-weight-bold':'')}`">{{$nova.formatCurrency(selectedcommentary.varianceamount1, '', false, true)}}</span>
                              <span class="mx-2">/</span>
                              <span :class="`${(selectedcommentary.varianceamount1>0)?'success--text font-weight-bold':((selectedcommentary.varianceamount1<0)?'error--text font-weight-bold':'')}`">{{$nova.formatCurrency(selectedcommentary.variancepercent1*100, '', false, true)}}{{((selectedcommentary.variancepercent1 !== 0)?' %':'')}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="" v-else>
                        <div class="body-1 pl-2" :style="`border-left: 4px solid ${tablecolors.heading.a} !important`">{{(commentarydata[0] || {}).amount1heading}} Vs {{(commentarydata[0] || {}).amount2heading}}</div>
                      </div>
                      <div class="mt-2">
                        <lb-textarea v-if="[2].indexOf(selectedcommentary.stage) === -1 && ispreparer" hidedetails v-model="selectedcommentary.commentary1" :label="(selectedcommentary.var1_cross_management || selectedcommentary.var1_cross_control || bulksubmit)?'Local Commentary Mandatory':'Local Commentary Optional'"/>
                        <div class="" v-else-if="!bulksubmit">
                          <div class="grey--text font-weight-bold">Local Commentary</div>
                          <div class="" v-if="selectedcommentary.commentary1">{{selectedcommentary.commentary1}}</div>
                          <div class="grey--text" v-else>No Data</div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="pr-4 pl-2 pb-4" style="width:50%">
                      <div v-if="!bulksubmit" class="pt-2">
                        <div class="body-1 pl-2" :style="`border-left: 4px solid ${tablecolors.heading.b} !important`">{{selectedcommentary.amount1heading}} Vs {{selectedcommentary.amount3heading}}</div>
                        <div class="d-flex">
                          <div class="mr-2 py-1">
                            <div class="font-weight-bold grey--text">{{selectedcommentary.amount1heading}}</div>
                            <div class="subtitle-2">{{$nova.formatCurrency(selectedcommentary.amount1, '', false, true)}}</div>
                          </div>
                          <div class="mx-2 py-1">
                            <div class="font-weight-bold grey--text">{{selectedcommentary.amount3heading}}</div>
                            <div class="subtitle-2">{{$nova.formatCurrency(selectedcommentary.amount3, '', false, true)}}</div>
                          </div>
                          <div class="ml-2 py-1">
                            <div class="font-weight-bold grey--text">Variance Amt / %</div>
                            <div class="subtitle-2">
                              <span :class="`${(selectedcommentary.varianceamount2>0)?'success--text font-weight-bold':((selectedcommentary.varianceamount2<0)?'error--text font-weight-bold':'')}`">{{$nova.formatCurrency(selectedcommentary.varianceamount2, '', false, true)}}</span>
                              <span class="mx-2">/</span>
                              <span :class="`${(selectedcommentary.varianceamount2>0)?'success--text font-weight-bold':((selectedcommentary.varianceamount2<0)?'error--text font-weight-bold':'')}`">{{$nova.formatCurrency(selectedcommentary.variancepercent2*100, '', false, true)}}{{((selectedcommentary.variancepercent2 !== 0)?' %':'')}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="" v-else>
                        <div class="body-1 pl-2" :style="`border-left: 4px solid ${tablecolors.heading.b} !important`">{{(commentarydata[0] || {}).amount1heading}} Vs {{(commentarydata[0] || {}).amount3heading}}</div>
                      </div>
                      <div class="mt-2">
                        <lb-textarea v-if="[2].indexOf(selectedcommentary.stage) === -1 && ispreparer" hidedetails v-model="selectedcommentary.commentary2" :label="(selectedcommentary.var2_cross_management || selectedcommentary.var2_cross_control || bulksubmit)?'Local Commentary Mandatory':'Local Commentary Optional'"/>
                        <div class="" v-else-if="!bulksubmit">
                          <div class="grey--text font-weight-bold">Local Commentary</div>
                          <div class="" v-if="selectedcommentary.commentary2">{{selectedcommentary.commentary2}}</div>
                          <div class="grey--text" v-else>No Data</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="px-4 mb-4" v-if="!bulksubmit && !(selectedcommentary.var1_cross_control || selectedcommentary.var1_cross_management || selectedcommentary.var2_cross_control || selectedcommentary.var2_cross_management)">
                    <div>Voluntary commentary submission reason</div>
                    <v-checkbox hide-details class="pa-0 ma-0" dense v-model="requestcommentarycontrol" label="For the purpose of controls?"></v-checkbox>
                    <v-checkbox hide-details class="pa-0 ma-0" dense v-model="requestcommentarymanagement" label="For the purpose of management?"></v-checkbox>
                  </div>
                  <div class="px-4 mb-4">
                    <div class="mb-2" v-if="isapprover && bulksubmit"><span class="font-weight-bold mr-1">Note:</span>Bulk approval/rejection cannot be done for GL line items whose variances breach the thresholds set for the purpose of controls.</div>
                    <lb-textarea v-if="([1].indexOf(selectedcommentary.stage) > -1 || bulksubmit) && isapprover" hidedetails v-model="selectedcommentary.approvereason" label="Local Approver's Approval/Reject Reason"/>
                    <div v-else-if="selectedcommentary.approvereason || selectedcommentary.rejectreason">
                      <div>
                        <div class="grey--text font-weight-bold">Local Approval/Rejection Reason</div>
                        <div class="">{{selectedcommentary.approvereason || selectedcommentary.rejectreason}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex mb-4">
                    <div class="pl-4 pr-2" style="width:50%">
                      <lb-textarea v-if="[1].indexOf(selectedcommentary.stage) > -1 && isapprover" hidedetails v-model="selectedcommentary.regionalcommentary1" :label="(selectedcommentary.var1_cross_management || selectedcommentary.var1_cross_control || bulksubmit)?'Regional Commentary Mandatory':'Regional Commentary Optional'"/>
                      <div class="" v-else-if="!bulksubmit">
                        <div class="grey--text font-weight-bold">Regional Commentary</div>
                        <div class="" v-if="selectedcommentary.regionalcommentary1">{{selectedcommentary.regionalcommentary1}}</div>
                        <div class="grey--text" v-else>No Data</div>
                      </div>
                    </div>
                    
                    <div class="pr-4 pl-2" style="width:50%">
                      <lb-textarea v-if="[1].indexOf(selectedcommentary.stage) > -1 && isapprover" hidedetails v-model="selectedcommentary.regionalcommentary2" :label="(selectedcommentary.var2_cross_management || selectedcommentary.var2_cross_control || bulksubmit)?'Regional Commentary Mandatory':'Regional Commentary Optional'"/>
                      <div class="" v-else-if="!bulksubmit">
                        <div class="grey--text font-weight-bold">Local Commentary</div>
                        <div class="" v-if="selectedcommentary.regionalcommentary2">{{selectedcommentary.regionalcommentary2}}</div>
                        <div class="grey--text" v-else>No Data</div>
                      </div>
                    </div>
                  </div>
                  <div class="px-4 mb-4">
                    <div class="mb-2" v-if="isregionalapprover && bulksubmit"><span class="font-weight-bold mr-1">Note:</span>Bulk approval/rejection cannot be done for GL line items whose variances breach the thresholds set for the purpose of controls.</div>
                    <lb-textarea v-if="([2].indexOf(selectedcommentary.stage) > -1 || bulksubmit) && isregionalapprover" hidedetails v-model="selectedcommentary.regionalapprovereason" label="Regional Approver's Approval/Reject Reason"/>
                    <div v-else-if="selectedcommentary.regionalapprovereason || selectedcommentary.regionalrejectreason">
                      <div>
                        <div class="grey--text font-weight-bold">Regional Approval/Rejection Reason</div>
                        <div class="">{{selectedcommentary.regionalapprovereason || selectedcommentary.regionalrejectreason}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex mt-4 px-4" v-if="(([2].indexOf(selectedcommentary.stage) === -1 || bulksubmit) && ispreparer) || (([1].indexOf(selectedcommentary.stage) > -1 || bulksubmit) && isapprover)">
                    <v-spacer></v-spacer>
                    <div v-if="(approvetype==='approve' || approvetype==='reject') && selectedcommentary.submitby === $store.state.user.id" class="error--text">Preparer and the Approver cannot be same person.</div>
                    <v-btn class="mr-1" v-if="([1].indexOf(selectedcommentary.stage) > -1 || bulksubmit) && isapprover" @click="approveCommentary()" color="success" small>Approve</v-btn>
                    <v-btn  v-if="([1].indexOf(selectedcommentary.stage) > -1 || bulksubmit) && isapprover" @click="rejectCommentary()" color="error" small>Reject</v-btn>
                    <template v-if="([2].indexOf(selectedcommentary.stage) === -1 || bulksubmit) && ispreparer">
                      <v-btn v-if="!bulksubmit" @click="saveCommentary()" class="mr-1" small>Save</v-btn>
                      <v-btn @click="addCommentary()" color="primary" small>Submit</v-btn>
                    </template>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item key="1" eager v-if="!bulksubmit">
                <div class="px-4">
                  <canvas id="trendchart" style="max-height: 300px"></canvas>
                </div>
              </v-tab-item>
            </v-tabs-items> 
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="requestcommentarydialog" max-width="600">
        <v-card class="" loader-height="2" :loading="requestcommentaryloading" :disabled="requestcommentaryloading">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text" v-if="bulksubmit">Bulk Request Commentary</span>
            <span class="subtitle-1 white--text" v-else>Request Commentary</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="requestcommentarydialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="pa-4">
            <div>
              <div class="title pb-2" v-if="bulksubmit">
                <span>{{multiselectdata.length}}</span>
                Selected
              </div>
              <div v-else>
                <div class="title">{{selectedcommentary.glcode}}</div>
                <div class="subtitle-1 mb-2">{{selectedcommentary.description}}</div>
              </div>
              <div class="pb-2">
                <v-checkbox hide-details class="pa-0 ma-0" dense v-model="requestcommentarycontrol" label="Commentary requested for the purpose of controls?"></v-checkbox>
                <v-checkbox hide-details class="pa-0 ma-0" dense v-model="requestcommentarymanagement" label="Commentary requested for the purpose of management?"></v-checkbox>
              </div>
              <div class="d-flex mt-2">
                <v-spacer></v-spacer>
                <v-btn @click="requestCommentary()" color="primary" small>Request</v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- <v-dialog v-model="approvecommentarydialog" max-width="600" persistent>
        <v-card class="" loader-height="2" :loading="commentaryloading" :disabled="commentaryloading">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text" v-if="bulksubmit">Bulk Approve Commentary</span>
            <span class="subtitle-1 white--text" v-else>Approve Commentary</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="approvecommentarydialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="pa-4">
            <div>
              <div class="title pb-2" v-if="bulksubmit">
                <span>{{multiselectdata.length}}</span>
                Selected
              </div>
              <div>
                <lb-textarea hidedetails v-model="selectedcommentary.approvereason" label="Approval Reason"/>
              </div>
              <div class="d-flex mt-2">
                <v-spacer></v-spacer>
                <v-btn @click="approveCommentary()" color="success" small>Submit</v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog> -->

      <v-dialog v-model="activitylogdialog" max-width="600">
        <v-card class="" loader-height="2" :loading="activitylogloading" :disabled="activitylogloading">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Activity Log</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="activitylogdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="pa-4">
            <template v-if="activitylogdata.length > 0">
              <div v-for="(v,k) in activitylogdata" :key="k">
                <div v-if="v.type==='approve'">
                  <div class="d-flex align-center my-1">
                    <v-icon color="success">mdi-check-circle</v-icon>
                    <span small label class="ml-4">Commentary Approved</span>
                    <span class="ml-4">{{$nova.formatDate((new Date(v.created_at)), "DD-MMM-YYYY, H:m", "")}}</span>
                    <span class="ml-4 font-weight-bold">By: {{(v.user_detail || {}).name}}</span>
                  </div>
                  <v-divider></v-divider>
                </div>
                <div v-else-if="v.type==='comment'">
                  <div class="d-flex align-center my-1">
                    <v-icon color="info">mdi-plus-circle</v-icon>
                    <span small label class="ml-4">Commentary Added</span>
                    <span class="ml-4">{{$nova.formatDate((new Date(v.created_at)), "DD-MMM-YYYY, H:m", "")}}</span>
                    <span class="ml-4 font-weight-bold">By: {{(v.user_detail || {}).name}}</span>
                  </div>
                  <v-divider></v-divider>
                </div>
                <div v-else-if="v.type==='reject'">
                  <div class="d-flex align-center my-1">
                    <v-icon color="error">mdi-close-circle</v-icon>
                    <span small label class="ml-4">Commentary Rejected</span>
                    <span class="ml-4">{{$nova.formatDate((new Date(v.created_at)), "DD-MMM-YYYY, H:m", "")}}</span>
                    <span class="ml-4 font-weight-bold">By: {{(v.user_detail || {}).name}}</span>
                  </div>
                  <v-divider></v-divider>
                </div>
                <div v-else-if="v.type==='data'">
                  <div class="d-flex align-center my-1">
                    <v-icon color="primary">mdi-cloud-upload-outline</v-icon>
                    <span small label class="ml-4">Data added</span>
                    <span class="ml-4">{{$nova.formatDate((new Date(v.created_at)), "DD-MMM-YYYY, H:m", "")}}</span>
                  </div>
                  <v-divider></v-divider>
                </div>
              </div>
            </template>
            <div v-else class="text-center title pa-5 grey--text">
              No Activity Log
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="customfiltersdialog" max-width="600">
        <v-card>
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Custom GL Filter</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="customfiltersdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="mt-4">
            <div>
              <div class="pt-2">
                <lb-string v-model="searchcustom" hidedetails/>
              </div>
              <perfect-scrollbar style="max-height: 40vh" class="mt-2">
                <template v-for="(v,k) in commentarydata">
                  <div :key="k" v-if="v.glgroup==='GL' && (searchcustom === '' || (v.glcode+v.description).replaceAll(' ','').toLowerCase().indexOf(searchcustom.toLowerCase()) > -1)">
                    <div class="d-flex align-center pl-2">
                      <v-checkbox class="mt-0" dense label="" v-model="customfilter" multiple :value="v.glcode" hide-details></v-checkbox>
                      <span class="mr-1">{{v.glcode}}</span>-
                      <span class="ml-1">{{v.description}}</span>
                    </div>
                    <v-divider></v-divider>
                  </div>
                </template>
              </perfect-scrollbar>
              
            </div>
          </v-card-text>
          <v-card-text class="d-flex align-center">
            <v-spacer></v-spacer>
            <v-btn small class="ml-2" color="primary" @click="saveCustomFilter()">Save Filter</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import cfilter from './Filter'
// let xlsx = require('xlsx');
let styledxlsx = require('sheetjs-style');
import {Chart, LineController,LineElement,PointElement,Title,Tooltip,CategoryScale,LinearScale} from 'chart.js';
Chart.register(LineController,LineElement,PointElement,Title,Tooltip,CategoryScale,LinearScale);

export default {
  name: 'commentaryworking',
  data: function(){
    return {
      loading: false,
      userid: '',
      toggleview: 0,
      ispreparer: false,
      isapprover: false,
      isregionalapprover: false,
      ismanager: false,
      rucycle: 0,
      commentarydata: [],
      commentarymetadata: {},
      commentarysummarydata: {},
      ignore0values: true,
      selectallpending: true,
      selectallpendingapproval: true,
      selectallapproved: true,
      selectallrejected: true,
      selectallimmaterial: false,
      hierarchyData: {},
      selectedrole: "",
      role: [],
      rolelist: {},
      selectedyear: "",
      year: [],
      yearlist: {},
      selectedperiod: "",
      period: [],
      perioddata: [],
      periodlist: {},
      selectednetwork: "",
      network: [],
      networkdata: [],
      networklist: {},
      selectedregion: "",
      region: [],
      regiondata: [],
      regionlist: {},
      selectedcountry: "",
      country: [],
      countrydata: [],
      countrylist: {},
      selectedreportingunit: "",
      reportingunit: [],
      reportingunitdata: [],
      reportingunitlist: {},
      statushelpdialog: false,
      commantorydialog: false,
      bulksubmit: false,
      bulkcommentall: false,
      bulkcommentreplace: false,
      viewdialog: false,
      viewdata: {},
      selectedcommentary: {},
      commentaryloading: false,
      approvetype: '',
      requestcommentarydialog: false,
      requestcommentaryloading: false,
      requestcommentarycontrol: false,
      requestcommentarymanagement: false,
      // approvecommentarydialog: false,
      // rejectcommentarydialog: false,
      // commentslevel: "",
      // commentsdialog: false,
      // commentsloading: false,
      trendchartdataset: [],
      trendchart: null,
      charttab: "",
      tablecolors: {
        heading: {"a": "", "b": ""},
        body: {"a": "", "b": ""}
      },
      // commentsdata: '',
      // commentserror: '',
      multiselectdata: [],
      activitylogdialog: false,
      activitylogloading: false,
      activitylogdata: [],
      bulksubmitfiledatafile: null,
      bulksubmitfiledata: {},
      bulksubmitfiledataerror: false,
      bulksubmitdialog: false,
      bulksubmitloading: false,
      expandedsubcategory: [],
      customfilter: [],
      searchcustom: "",
      customfiltersdialog: false,
      customfilterstoggle: false,
    }
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  components: {
    cfilter
  },
  activated() {
    this.refreshData();
  },
  mounted() {
    this.refreshData();
    let colors2 = this.$nova.getColors(3, 0.08);
    this.tablecolors = {
      heading: {"a": "#d0dcff", "b": "#efcef4"},
      body: {"a": colors2[2], "b": colors2[1]}
    }
    // console.log(this.tablecolors);
  },
  methods: {
    openSubmitDialog(dt, type){
      if(type === "add"){
        this.bulksubmit=false;
        this.viewdialog=false;
        this.selectedcommentary={...dt};
        this.selectedcommentary.savedcommentary1 = this.selectedcommentary.savedcommentary1 || this.selectedcommentary.commentary1 || "";
        this.selectedcommentary.savedcommentary2 = this.selectedcommentary.savedcommentary2 || this.selectedcommentary.commentary2 || "";
        this.selectedcommentary.commentary1 = this.selectedcommentary.savedcommentary1 || "";
        this.selectedcommentary.commentary2 = this.selectedcommentary.savedcommentary2 || "";
        this.requestcommentarycontrol = false;
        this.requestcommentarymanagement = false;
        this.getTrend(dt.glcode);
        this.approvetype='';
        this.commantorydialog=true;
      }
    },
    setFilter(k, value) {
      this.$refs.childfilter.setValue(k, value);
    },
    listenfilter(data) {
      if(data.key === "selectedrole") this.changeRole(data.value);
      else if(Object.prototype.hasOwnProperty.call(this,data.key)) this[data.key] = data.value;
      if(data.reload){
        this.$nextTick(() => {
          this.loadContent();
        });
      }
    },
    changeRole(t){
      this.selectedrole=t;
      localStorage.setItem("commentary_role", this.selectedrole);
    },
    toggleSubCategory(d){
      let index = this.expandedsubcategory.indexOf(d);
      if(index === -1) this.expandedsubcategory.push(d);
      else this.expandedsubcategory.splice(index, 1);
    },
    refreshData() {
      this.selectedrole = localStorage.getItem("commentary_role");
      this.customfilter = (localStorage.getItem("commentary_custom_filter") || "").split(",") || [];
      this.customfilterstoggle = localStorage.getItem("commentary_custom_filter_toggle")==="true"?true:false;
      if(Object.keys(this.rolelist).indexOf(this.selectedrole) === -1) this.changeRole("submitter");
      this.userid = this.$store.state.user.id;
      this.multiselectdata = [];
      this.ispreparer = false;
      this.isapprover = false;
      this.isregionalapprover = false;
      this.bulksubmit = false;
      this.ismanager = false;
    },
    getData() {
      this.loading = true;
      this.axios.post("/v2/varianceanalysis/analysis/getaccesshierarchy", {filter: {role: this.selectedrole}}).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0) {
          this.hierarchyData = dt.data.data[0];
        }
        else throw new Error ("Error fetching data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
        this.loadContent();
      })
    },
    loadContent() {
      this.multiselectdata = [];
      if(this.selectedperiod === "all") return this.loadPeriod();
      else if(this.selectednetwork === "all") return this.loadNetwork();
      else if(this.selectedregion === "all") return this.loadRegion();
      else if(this.selectedcountry === "all") return this.loadCountry();
      else if(this.selectedreportingunit === "all") return this.loadReportingUnit();
      else if(this.selectedreportingunit) return this.loadData();
    },
    clearMultiselectData() {
      this.multiselectdata = [];
    },
    readBulkSubmit(){
      let file = this.bulksubmitfiledatafile;
      if((file || {}).name && !this.bulksubmitloading){
        this.bulksubmitfiledataerror = false;
        this.bulksubmitloading = true;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if(["xlsx","xls","xlsm","xlsb","pdf"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb,pdf file extension supported";
          return this.$nova.readExcelFile(file, "TOP SHEET");
        }).then(dt => {
          if(JSON.stringify(dt[0] || []) === JSON.stringify(['GL Code', 'Commentary 1', 'Commentary 2'])){
            let gllist = [];
            let uploaddata = {};
            let haserror = false; 
            for (let i = 0; i < dt.length; i++) {
              if(i > 0){
                const el = dt[i];
                let glcode = (el[0] || "").toString();
                let ndt = {data: el};
                gllist.push(glcode)
                ndt.__found = false;
                uploaddata[glcode] = ndt;
              }
            }
            for (let i = 0; i < this.commentarydata.length; i++) {
              const el = this.commentarydata[i];
              let glcode = (el.glcode || "").toString();
              if(gllist.indexOf(glcode) > -1){
                uploaddata[glcode].__found = true;
                uploaddata[glcode].error = ""
                uploaddata[glcode].description = el.description
                if(el.var1_cross_control || el.var1_cross_management){
                  if(!uploaddata[glcode].data[1]){
                    uploaddata[glcode].error = "Commentary 1 is required";
                    haserror = true;
                  }
                }else if(uploaddata[glcode].data[1]) {
                  uploaddata[glcode].error = "Commentary 1 cannot be added as the variance doesn't cross the threshold limit";
                  haserror = true;
                }
                if(el.var2_cross_control || el.var2_cross_management){
                  if(!uploaddata[glcode].data[2]){
                    if(uploaddata[glcode].error !== "") uploaddata[glcode].error = uploaddata[glcode].error + ", ";
                    uploaddata[glcode].error = uploaddata[glcode].error + "Commentary 2 is required";
                    haserror = true;
                  }
                }else if(uploaddata[glcode].data[2]) {
                  if(uploaddata[glcode].error !== "") uploaddata[glcode].error = uploaddata[glcode].error + ", ";
                  uploaddata[glcode].error = uploaddata[glcode].error + "Commentary 2 cannot be added as the variance doesn't cross the threshold limit";
                  haserror = true;
                }
              }
            }
            for (const k in uploaddata) {
                if (Object.hasOwnProperty.call(uploaddata, k)) {
                  const el = uploaddata[k];
                  if(el.__found === false) haserror = true;
                }
              }
            this.bulksubmitfiledataerror = haserror;
            this.bulksubmitfiledata = uploaddata;
          }else throw new Error("Columns 'GL Code','Commentary 1','Commentary 2' are required in the same order");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.bulksubmitfiledatafile = null;
          this.bulksubmitloading = false;
        });
      }
    },
    addCommentary() {
      let url = "/v2/varianceanalysis/analysis/addcommentary/"+this.selectedcommentary._id;
      let id = this.selectedcommentary._id;
      // console.log(this.selectedcommentary);
      if(this.bulksubmit) {
        id = this.multiselectdata;
        url = "/v2/varianceanalysis/analysis/addbulkcommentary"
      }
      let thresholdcross = {};
      if(this.bulkcommentall){
        thresholdcross = {crosscontrolthreshold: this.requestcommentarycontrol, crossmanagementthreshold: this.requestcommentarymanagement}
      }
      if(!this.bulksubmit && !(this.selectedcommentary.var1_cross_control || this.selectedcommentary.var1_cross_management || this.selectedcommentary.var2_cross_control || this.selectedcommentary.var2_cross_management)){
        this.$store.commit("sbError", "Voluntary commentary submission reason is required.");
      }else if(this.bulksubmit && this.bulkcommentall && !(this.selectedcommentary.var1_cross_control || this.selectedcommentary.var1_cross_management || this.selectedcommentary.var2_cross_control || this.selectedcommentary.var2_cross_management)){
        this.$store.commit("sbError", "Voluntary commentary submission reason is required.");
      }else{
        this.commentaryloading = true;
        this.axios.post(url, {id: id, filter: {all: this.bulkcommentall, replace: this.bulkcommentreplace, ...thresholdcross}, data: {commentary1: this.selectedcommentary.commentary1, commentary2: this.selectedcommentary.commentary2}}).then(dt => {
          if(dt.data.status === "success") {
            this.$store.commit("sbSuccess", "Commentary added");
            this.commantorydialog = false;
            if(this.bulksubmit) {
              this.loadContent();
            }
            else{
              this.commentarydata[this.selectedcommentary.__key].commentary1 = this.selectedcommentary.commentary1;
              this.selectedcommentary.savedcommentary1 = "";
              this.commentarydata[this.selectedcommentary.__key].commentary2 = this.selectedcommentary.commentary2;
              this.selectedcommentary.savedcommentary2 = "";
              this.commentarydata[this.selectedcommentary.__key].stage = 1;
            }
          }
          else throw new Error (dt.data.message || "Error adding commentary");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.commentaryloading = false;
        })
      }
    },
    saveCommentary() {
      this.commentaryloading = true;
      let url = "/v2/varianceanalysis/analysis/addcommentary/"+this.selectedcommentary._id;
      let id = this.selectedcommentary._id;
      // console.log(this.selectedcommentary);
      if(this.bulksubmit) {
        id = this.multiselectdata;
        url = "/v2/varianceanalysis/analysis/addbulkcommentary"
      }
      this.axios.post(url, {id: id, filter: {save: true, all: this.bulkcommentall, replace: this.bulkcommentreplace}, data: {commentary1: this.selectedcommentary.commentary1, commentary2: this.selectedcommentary.commentary2}}).then(dt => {
        if(dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Commentary saved");
          if(this.bulksubmit) {
            this.loadContent();
          }
          else{
            this.commentarydata[this.selectedcommentary.__key].savedcommentary1 = this.selectedcommentary.commentary1;
            this.selectedcommentary.savedcommentary1 = this.selectedcommentary.commentary1;
            this.commentarydata[this.selectedcommentary.__key].savedcommentary2 = this.selectedcommentary.commentary2;
            this.selectedcommentary.savedcommentary2 = this.selectedcommentary.commentary2;
          }
        }
        else throw new Error (dt.data.message || "Error adding commentary");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.commentaryloading = false;
      })
    },
    bulkUploadCommentaryByGL() {
      this.bulksubmitloading = true;
      let url = "/v2/varianceanalysis/analysis/bulkaddcommentarybygl";
      let ndt = [];
      for (const k in this.bulksubmitfiledata) {
        if (Object.hasOwnProperty.call(this.bulksubmitfiledata, k)) {
          const el = this.bulksubmitfiledata[k];
          ndt.push({glcode: el.data[0], commentary1: el.data[1], commentary2: el.data[2]});
        }
      }
      this.axios.post(url, {data: ndt, filter: {reportingunit: this.selectedreportingunit, period: this.selectedperiod}}).then(dt => {
        if(dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Bulk Commentary added");
          this.bulksubmitdialog = false;
          this.loadContent();
        }
        else throw new Error (dt.data.message || "Error adding bulk commentary");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.bulksubmitloading = false;
      })
    },
    requestCommentary() {
      let gls = [];
      if(this.bulksubmit) gls = this.multiselectdata;
      else gls = [this.selectedcommentary._id];
      this.requestcommentaryloading = true;
      let url = "/v2/varianceanalysis/analysis/requestcommentary";
      this.axios.post(url, {filter: {cycle: this.commentarycycledata._id, gls: gls, crosscontrolthreshold: this.requestcommentarycontrol, crossmanagementthreshold: this.requestcommentarymanagement}}).then(dt => {
        if(dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Commentary requested");
          this.requestcommentarydialog = false;
          if(this.bulksubmit) {
            this.loadContent();
          }
          else {
            this.commentarydata[this.selectedcommentary.__key].stage = 0;
            this.commentarydata[this.selectedcommentary.__key].forcerequest = true;
            if(this.requestcommentarycontrol){
              this.commentarydata[this.selectedcommentary.__key].var1_cross_control = true;
              this.commentarydata[this.selectedcommentary.__key].var2_cross_control = true;
            }
            if(this.requestcommentarymanagement){
              this.commentarydata[this.selectedcommentary.__key].var1_cross_management = true;
              this.commentarydata[this.selectedcommentary.__key].var2_cross_management = true;
            }
          }
        }
        else throw new Error (dt.data.message || "Error requesting commentary");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.requestcommentaryloading = false;
      })
    },
    approveCommentary() {
      this.commentaryloading = true;
      let url = "/v2/varianceanalysis/analysis/approvecommentary";
      let id = this.selectedcommentary._id;
      if(this.bulksubmit) {
        id = this.multiselectdata;
        url = "/v2/varianceanalysis/analysis/approvebulkcommentary"
      }
      this.axios.post(url, {id: id, filter: {reportingunit: this.selectedreportingunit, period: this.selectedperiod}, data: {approvereason: this.selectedcommentary.approvereason}}).then(dt => {
        if(dt.data.status === "success") {
          this.commantorydialog = false;
          if(this.bulksubmit) {
            this.$store.commit("sbSuccess", "Bulk commentary approved");
            this.loadContent();
          }
          else {
            this.$store.commit("sbSuccess", "Commentary approved");
            this.commentarydata[this.selectedcommentary.__key].stage = 2;
            this.commentarydata[this.selectedcommentary.__key].approvereason = this.selectedcommentary.approvereason;
          }
        }
        else throw new Error (dt.data.message || "Error approving commentary");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.commentaryloading = false;
      })
    },
    rejectCommentary() {
      this.commentaryloading = true;
      let url = "/v2/varianceanalysis/analysis/rejectcommentary";
      let id = this.selectedcommentary._id;
      if(this.bulksubmit) {
        id = this.multiselectdata;
        url = "/v2/varianceanalysis/analysis/rejectbulkcommentary"
      }
      this.axios.post(url, {id: id, filter: {reportingunit: this.selectedreportingunit, period: this.selectedperiod}, data: {rejectreason: this.selectedcommentary.approvereason}}).then(dt => {
        if(dt.data.status === "success") {
          this.commantorydialog = false;
          if(this.bulksubmit) {
            this.$store.commit("sbSuccess", "Bulk commentary rejected");
            this.loadContent();
          }
          else {
            this.$store.commit("sbSuccess", "Commentary rejected");
            this.commentarydata[this.selectedcommentary.__key].stage = -1;
            this.commentarydata[this.selectedcommentary.__key].approvereason = this.selectedcommentary.approvereason;
          }
        }
        else throw new Error (dt.data.message || "Error rejecting commentary");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.commentaryloading = false;
      })
    },
    // addComment() {
    //   this.commentsloading = true;
    //   let data = {comment: this.commentsdata, data: this.selectedcommentary._id || ''}
    //   if(this.commentslevel === "ru") data = {comment: this.commentsdata, data: this.selectedreportingunit || ''};
    //   this.axios.post("/v2/varianceanalysis/analysis/addcomment", {filter: {level: this.commentslevel}, data: [data]}).then(dt => {
    //     if(dt.data.status === "success"){
    //       if(dt.data.data.length > 0){
    //         this.$store.commit("sbSuccess", "Comment Added");
    //         if(this.commentslevel === "ru"){
    //           this.commentarymetadata.comments = this.commentarymetadata.comments || [];
    //           this.commentarymetadata.comments.push(dt.data.data[0]);
    //         }else{
    //           this.selectedcommentary.comments = this.selectedcommentary.comments || [];
    //           this.selectedcommentary.comments.push(dt.data.data[0]);
    //           this.addeditresponseheader = this.selectedcommentary;
    //         }
    //         this.commentsdata='';
    //         this.commentserror='';
    //       }else throw new Error ("error adding comment");
    //     }else{
    //       if (dt.data.data.length > 0){
    //         if(dt.data.data[0].index0) this.addeditinforownererror = dt.data.data[0].index0;
    //       }
    //       throw new Error (dt.data.message);
    //     }
    //   }).catch(err => {
    //     this.$store.commit("sbError", err.message || err || "Unknown error!");
    //     console.log(err);
    //   }).finally(() => {
    //     this.commentsloading = false;
    //   })
    // },
    // markCommentAsRead(x, level) {
    //   if((x.comments || []).length > 0){
    //     let commentkey = x.comments.length -1;
    //     let comment = x.comments[commentkey]; 
    //     let nid = x._id;
    //     if(level === "ru") nid = this.selectedreportingunit;
    //     if((comment.readby || []).indexOf(this.$store.state.user.id) === -1){
    //       this.axios.post("/v2/varianceanalysis/analysis/markcommentasread/"+nid, {filter: {level: level}}).then(dt => {
    //         if(dt.data.status === "success" && dt.data.response.nModified > 0){
    //           this.selectedcommentary.comments[commentkey].readby.push(this.$store.state.user.id);
    //         }else throw new Error (dt.data.message);
    //       }).catch(err => {
    //         this.$store.commit("sbError", err.message || err || "Unknown error!");
    //         console.log(err);
    //       }).finally(() => {
    //         this.commentsloading = false;
    //       })
    //     }
    //   }
    // },
    getTrend(gl) {
      this.commentaryloading = true;
      this.perioddata = [];
      this.trendchartdataset = [];
      this.axios.post("/v2/varianceanalysis/analysis/gettrend/", {filter: {glcode: gl, year: this.selectedyear, period: this.selectedperiod, reportingunit: this.selectedreportingunit}}).then(dt => {
        if(dt.data.status === "success") {
          let trenddata = [];
          let months = [];
          let trendchartdata = {};
          let colors = this.$nova.getColors(dt.data.data.length, 0.5);

        for (const i of dt.data.data) {
            months.push((i.period_detail || {}).name);
            trenddata.push({number: (i.period_detail || {}).number, name: (i.period_detail || {}).name, amount1: i.amount1, amount2: i.amount2, amount3: i.amount3, amount1heading: i.amount1heading, amount2heading: i.amount2heading, amount3heading: i.amount3heading } )
            trendchartdata[i.amount1heading] = trendchartdata[i.amount1heading] || {label: i.amount1heading, data: [], backgroundColor: colors[0], borderColor: colors[0]};
            trendchartdata[i.amount1heading].data.push(i.amount1.toFixed(0) || 0);
            trendchartdata[i.amount2heading] = trendchartdata[i.amount2heading] || {label: i.amount2heading, data: [], backgroundColor: colors[1], borderColor: colors[1]};
            trendchartdata[i.amount2heading].data.push(i.amount2.toFixed(0) || 0);
            trendchartdata[i.amount3heading] = trendchartdata[i.amount3heading] || {label: i.amount3heading, data: [], backgroundColor: colors[2], borderColor: colors[2]};
            trendchartdata[i.amount3heading].data.push(i.amount3.toFixed(0) || 0);
        }
          for (const k in trendchartdata) {
            if (Object.hasOwnProperty.call(trendchartdata, k)) {
              const el = trendchartdata[k];
              this.trendchartdataset.push(el);
            }
          }
          let chartconfig = {
            type: 'line',
            data: {
              labels: months,
              datasets: this.trendchartdataset,
            },
            options: {
              responsive: true,
              legend: {
                display: false
              },
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: ''
                }
              }
            }
          }
          // console.log(chartconfig);
          // console.log(document.getElementById("trendchart"));
          // return this.$nextTick(() => {
            if(this.trendchart) this.trendchart.destroy();
            this.trendchart = new Chart(document.getElementById("trendchart"), chartconfig);
          // });
        }
        else throw new Error (dt.data.message || "Error fetching data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.commentaryloading = false;
      })
    },
    loadPeriod() {
      this.loading = true;
      // this.perioddata = [];
      return this.axios.post("/v2/varianceanalysis/analysis/getperioddata/", {filter: {year: this.selectedyear, role: this.selectedrole, network: this.selectednetwork, region: this.selectedregion, country: this.selectedcountry, reportingunit: this.selectedreportingunit}}).then(dt => {
        if(dt.data.status === "success") {
          this.perioddata = dt.data.data || [];
        }
        else throw new Error (dt.data.message || "Error fetching data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    loadNetwork() {
      this.loading = true;
      // this.networkdata = [];
      return this.axios.post("/v2/varianceanalysis/analysis/getnetworkdata/", {filter: {year: this.selectedyear, role: this.selectedrole, period: this.selectedperiod, region: this.selectedregion, country: this.selectedcountry, reportingunit: this.selectedreportingunit}}).then(dt => {
        if(dt.data.status === "success") {
          this.networkdata = dt.data.data || [];
          if(this.networkdata.length === 1){
            this.selectednetwork = this.networkdata[0]._id;
            this.setFilter('selectednetwork', this.networkdata[0]._id);
          }
        }
        else throw new Error (dt.data.message || "Error fetching data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    loadRegion() {
      this.loading = true;
      // this.regiondata = [];
      return this.axios.post("/v2/varianceanalysis/analysis/getregiondata/", {filter: {year: this.selectedyear, role: this.selectedrole, network: this.selectednetwork, period: this.selectedperiod, country: this.selectedcountry, reportingunit: this.selectedreportingunit}}).then(dt => {
        if(dt.data.status === "success") {
          this.regiondata = dt.data.data || [];
          if(this.regiondata.length === 1){
            this.selectedregion = this.regiondata[0]._id;
            this.setFilter('selectedregion', this.regiondata[0]._id);
          }
        }
        else throw new Error (dt.data.message || "Error fetching data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    loadCountry() {
      this.loading = true;
      // this.countrydata = [];
      return this.axios.post("/v2/varianceanalysis/analysis/getcountrydata/", {filter: {year: this.selectedyear, role: this.selectedrole, network: this.selectednetwork, region: this.selectedregion, period: this.selectedperiod, reportingunit: this.selectedreportingunit}}).then(dt => {
        if(dt.data.status === "success") {
          this.countrydata = dt.data.data || [];
          if(this.countrydata.length === 1){
            this.selectedcountry = this.countrydata[0]._id;
            this.setFilter('selectedcountry', this.countrydata[0]._id);
          }
        }
        else throw new Error (dt.data.message || "Error fetching data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    loadReportingUnit() {
      this.loading = true;
      // this.reportingunitdata = [];
      return this.axios.post("/v2/varianceanalysis/analysis/getreportingunit/", {filter: {year: this.selectedyear, role: this.selectedrole, network: this.selectednetwork, region: this.selectedregion, country: this.selectedcountry, period: this.selectedperiod}}).then(dt => {
        if(dt.data.status === "success") {
          this.reportingunitdata = dt.data.data || [];
        }
        else throw new Error ("Error fetching data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    updateCycle(type) {
      this.loading = true;
      let successflag = false;
      return this.axios.post("/v2/varianceanalysis/analysis/updatecommentarycycle/", {filter: {commentary: this.commentarysummarydata.cycle_detail._id, type: type}}).then(dt => {
        if(dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Commentary cycle updated");
          successflag = true
        }
        else throw new Error ("Error updating data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
        if(successflag) this.loadData();
      })
    },
    sendApprovalReminder() {
      this.loading = true;
      return this.axios.post("/v2/varianceanalysis/analysis/sendapproverreminder/"+this.selectedreportingunit).then(dt => {
        if(dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Remainder mail sent to approver");
        }
        else throw new Error ("Error sending mail");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    sendSubmitterReminder() {
      this.loading = true;
      return this.axios.post("/v2/varianceanalysis/analysis/sendsubmitterreminder/"+this.selectedreportingunit).then(dt => {
        if(dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Remainder mail sent to preparer");
        }
        else throw new Error ("Error sending mail");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    readActivity(id) {
      this.activitylogloading = true;
      this.activitylogdialog = true;
      this.reportingunitdata = [];
      this.activitylogdata = [];
      this.axios.post("/v2/varianceanalysis/analysis/readactivity/"+id).then(dt => {
        if(dt.data.status === "success") {
          this.activitylogdata = dt.data.data || [];
        }
        else throw new Error ("Error fetching data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.activitylogloading = false;
      })
    },
    getStageText(x){
      if(x === -1) return "Rejected commentary";
      else if(x === 0) return "Commentary awaited";
      else if(x === 1) return "Commentary awaiting approval";
      else if(x === 2) return "Approved commentary";
      else if(x === 3) return "Immaterial variance";
      else if(x === 4) return "Nil value accounts";
    },
    downloadExcel() {
      // Acquire Data (reference to the HTML table)
      return Promise.resolve().then(() => {
        return this.loadContent();
      }).then(() => {
        let workbook = styledxlsx.utils.book_new();
        let primarycolor = "d8e8f0";
        let heading1 = "bfffbf";
        let heading2 = (this.tablecolors.heading.a || "").toString().replaceAll("#", "").toUpperCase();
        let heading3 = (this.tablecolors.heading.b || "").toString().replaceAll("#", "").toUpperCase();
        let body1 = "E3FFE3";
        let body2 = "E8EEFF";
        let body3 = "F2E1F5";
        let scolor = "078f0e";
        let ecolor = "d11b1b";
        // console.log(this.reportingunitlist[this.selectedreportingunit]);
        // console.log(primarycolor, this.tablecolors, heading1, heading2, heading3, body1, body2, body3);
        let dt = [
          [
            ""
          ],
          [
            "", 
            {v: this.reportingunitlist[this.selectedreportingunit].name || "", s: {font: {bold: true, sz: 20}}}, 
          ],
          [
            "", 
            {v: (this.periodlist[this.selectedperiod].name || "") + " - " + this.selectedyear, s: {font: {bold: true, sz: 14}}}, 
          ],
          [
            ""
          ],
          [
            "",
            {v: "GL Code", s: {font: {bold: true}, fill: {fgColor: {rgb: primarycolor}}}},
            {v: "Description", s: {font: {bold: true}, fill: {fgColor: {rgb: primarycolor}}}},
            {v: "Category", s: {font: {bold: true}, fill: {fgColor: {rgb: primarycolor}}}},
            {v: "Actual Amount", s: {font: {bold: true}, fill: {fgColor: {rgb: heading1}}}},
            {v: "Proforma Amount", s: {font: {bold: true}, fill: {fgColor: {rgb: heading2}}}},
            {v: "Variance Amt(Act vs Pro)", s: {font: {bold: true}, fill: {fgColor: {rgb: heading2}}}},
            {v: "Variance %(Act vs Pro)", s: {font: {bold: true}, fill: {fgColor: {rgb: heading2}}}},
            {v: "Act vs Pro Commentary", s: {font: {bold: true}, fill: {fgColor: {rgb: heading2}}}},
            {v: "Forecast Amount", s: {font: {bold: true}, fill: {fgColor: {rgb: heading3}}}},
            {v: "Variance Amt(Act vs For)", s: {font: {bold: true}, fill: {fgColor: {rgb: heading3}}}},
            {v: "Variance %(Act vs For)", s: {font: {bold: true}, fill: {fgColor: {rgb: heading3}}}},
            {v: "Act vs For Commentary", s: {font: {bold: true}, fill: {fgColor: {rgb: heading3}}}},
            {v: "Status", s: {font: {bold: true}, fill: {fgColor: {rgb: primarycolor}}}},
          ]];
        for (const v of this.commentarydata) {
          dt.push([
            "",
            v.glcode,
            v.sub_category, 
            v.description, 
            {v: this.$nova.formatCurrency(v.amount1.toFixed(1), '', false, true), s: {fill: {fgColor: {rgb: body1}}}},
            {v: this.$nova.formatCurrency(v.amount2.toFixed(1), '', false, true), s: {fill: {fgColor: {rgb: body2}}}},
            {v: this.$nova.formatCurrency(v.varianceamount1.toFixed(1), '', false, true), s: {font: {color: {rgb: (v.varianceamount1>0)?scolor:ecolor}}, fill: {fgColor: {rgb: body2}}}},
            {v: this.$nova.formatCurrency((v.variancepercent1*100).toFixed(1), '', false, true) + ((v.variancepercent1 !== 0)?'%':''), s: {font: {color: {rgb: (v.varianceamount1>0)?scolor:ecolor}}, fill: {fgColor: {rgb: body2}}}},
            {v: v.commentary1, s: {fill: {fgColor: {rgb: body2}}}},
            {v: this.$nova.formatCurrency(v.amount3.toFixed(1), '', false, true), s: {fill: {fgColor: {rgb: body3}}}},
            {v: this.$nova.formatCurrency(v.varianceamount2.toFixed(1), '', false, true), s: {font: {color: {rgb: (v.varianceamount2>0)?scolor:ecolor}}, fill: {fgColor: {rgb: body3}}}},
            {v: this.$nova.formatCurrency((v.variancepercent2*100).toFixed(1), '', false, true) + ((v.variancepercent2 !== 0)?'%':''), s: {font: {color: {rgb: (v.varianceamount2>0)?scolor:ecolor}}, fill: {fgColor: {rgb: body3}}}},
            {v: v.commentary2, s: {fill: {fgColor: {rgb: body3}}}},
            this.getStageText(v.stage)
          ])
        }
        // console.log(dt);
  
        // Extract Data (create a workbook object from the table)
        let worksheet = styledxlsx.utils.aoa_to_sheet(dt);
    //     worksheet["A1"].s = { // set the style for target cell
    // font: {
    //   name: '宋体',
    //   sz: 24,
    //   bold: true,
    //   color: {
    //     rgb: "FFFFAA00"
    //   }
    // },
  // };
        styledxlsx.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
  
        // // Process Data (add a new row)
        // var ws = workbook.Sheets["Sheet1"];
        // xlsx.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1});
  
        // Package and Release Data (`writeFile` tries to write and save an XLSB file)
        styledxlsx.writeFile(workbook, "Report.xlsx");
  
        // console.log(xlsx);
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      })

    },
    loadData() {
      this.loading = true;
      // this.commentarydata = [];
      // this.commentarymetadata = {};
      // this.commentarysummarydata = {};
      return this.axios.post("/v2/varianceanalysis/analysis/getreportingunitdata/", {filter: {reportingunit: this.selectedreportingunit, period: this.selectedperiod, role: this.selectedrole}}).then(dt => {
        if(dt.data.status === "success") {
          this.commentarydata = (dt.data.data[0] || {}).data || [];
          this.commentarymetadata = (dt.data.data[0] || {}).meta || {};
          this.commentarysummarydata = (dt.data.data[0] || {}).summary || {};
          this.commentarycycledata = (dt.data.data[0] || {}).cycle;
          this.rucycle = (this.commentarysummarydata.cycle_detail || {}).stage;
          this.ispreparer = false;
          this.isapprover = false;
          this.isregionalapprover = false;
          this.ismanager = false;
          if((this.commentarymetadata.submitter || []).indexOf(this.userid) > -1) this.ispreparer = true;
          else if((this.commentarymetadata.approver || []).indexOf(this.userid) > -1) this.isapprover = true;
          else if((this.commentarymetadata.regional_approver || []).indexOf(this.userid) > -1) this.isregionalapprover = true;
          else if((this.commentarymetadata.manager || []).indexOf(this.userid) > -1) this.ismanager = true;
        }
        else throw new Error ("Error fetching data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    pushtoExpand: function(d){
      this.expandedsubcategory.push(d);
    },
    saveCustomFilter: function(){
      localStorage.setItem("commentary_custom_filter", this.customfilter.join(","));
      if(this.customfilter.length > 0) this.customfilterstoggle = true;
      else this.customfilterstoggle = false;
      localStorage.setItem("commentary_custom_filter_toggle", this.customfilterstoggle);
      this.customfiltersdialog = false;
    }
  },
  computed: {
    filteredcommentarydata: function(){
      let list = [];
      let currentcategory = "xxxxxx";
      let headerlist = {};

      for (const i of this.commentarydata) {
        if(i.glgroup === "Sub-total"){
          i.type="header";
          headerlist[i.sub_category.replaceAll(" ", "_")] = i;
        }
      }
      for (let i = 0; i < this.commentarydata.length; i++) {
        const el = this.commentarydata[i];
        if(el.glgroup === "Sub-total"){
          el.type="header";
          el.__key = i;
          headerlist[el.sub_category.replaceAll(" ", "_")] = el;
        }
      }
      this.clearMultiselectData();
      for (let i = 0; i < this.commentarydata.length; i++) {
        const el = this.commentarydata[i];
        let ignore = false;
        if(this.customfilterstoggle && (el.glgroup !== 'GL' || this.customfilter.indexOf(el.glcode) === -1)) ignore = true
        if(this.ignore0values){
          if(el.stage === 3) ignore = true;
        }
        if(!this.selectallpending){
          if(el.stage === 0) ignore = true;
        }
        if(!this.selectallpendingapproval){
          if(el.stage === 1) ignore = true;
        }
        if(!this.selectallapproved){
          if(el.stage === 2) ignore = true;
        }
        if(!this.selectallrejected){
          if(el.stage === -1) ignore = true;
        }
        if(!this.selectallimmaterial){
          if(el.stage === 4) ignore = true;
        }
        if(!ignore) {
          el.__key = i;
          if(this.customfilterstoggle) {
            if(this.expandedsubcategory.indexOf(el.sub_category) === -1) this.pushtoExpand(el.sub_category);
            list.push(el);
          }
          else{
            if(currentcategory !== el.sub_category && el.glgroup==="GL"){
              // console.log(el.description, currentcategory, el.sub_category, headerlist[el.sub_category.replaceAll(" ", "_")]);
              if(headerlist[el.sub_category.replaceAll(" ", "_")]){
                list.push(headerlist[el.sub_category.replaceAll(" ", "_")]);
                list.push(el);
              }else {
                list.push(el);
                this.pushtoExpand(el.sub_category);
              }
              currentcategory = el.sub_category;
            }else if(el.glgroup !== "Sub-total") list.push(el);
          }
        }
      }
      // console.log(list);
      return list;
    },
  },
  watch: {
    "customfilterstoggle": function(v){
      localStorage.setItem("commentary_custom_filter_toggle", v)
    },
  }
}
</script>
